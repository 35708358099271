import { Box, Skeleton } from "@mui/material";

import { BACKEND_ROUTES } from "../../../../backendRoutes";
import { Canvas } from "../../../../components/Canvas";
import { FetchErrorAlert } from "../../../../components/FetchErrorAlert";
import PropTypes from "prop-types";
import { toRgb } from "../../../../utils/colors";
import useSWR from "swr";

const DEFAULT_CONFIG = {
    lineWidth: 2,
    classes: {
        default: { drawType: "polygon", color: "black" },
    },
};

const scaleCoord = (coord, canvasSize, imageSize) =>
    (coord * canvasSize) / imageSize;

const drawPolygon = (
    context,
    segmentation,
    imageWidth,
    imageHeight,
    canvasSize,
    color,
    alpha = 0
) => {
    // iterate through each even coord
    context.beginPath();
    for (let i = 0; i < segmentation.length; i += 2) {
        context.lineTo(
            scaleCoord(segmentation[i], canvasSize, imageWidth),
            scaleCoord(segmentation[i + 1], canvasSize, imageHeight)
        );
    }
    context.fillStyle = `rgba(${toRgb(color).join(",")}, ${alpha})`;
    context.strokeStyle = color;
    context.closePath();
    context.stroke();
    context.fill();
};

export const SegmentedImageDisplay = ({
    config,
    image,
    roi = [0, 0, 0, 0],
    annotations,
    categories,
    canvasSize,
    onClick,
    alpha,
}) => {
    const {
        data: src,
        error: imageFetchError,
        isValidating: imageLoading,
    } = useSWR(`${BACKEND_ROUTES.DEEP_LEARNING_MODELS}/images?uri=${image}`, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    });

    const imageWidth = roi[2];
    const imageHeight = roi[3];

    // Fill not provided properties with default values
    const fullConfig = {
        ...DEFAULT_CONFIG,
        ...config,
        classes: {
            ...config?.classes,
            ...DEFAULT_CONFIG.classes,
        },
    };

    const draw = (context) => {
        const img = new Image();
        img.src = src;

        img.onload = () => {
            context.clearRect(0, 0, canvasSize, canvasSize);
            context.drawImage(img, ...roi, 0, 0, canvasSize, canvasSize);

            context.lineWidth = fullConfig.lineWidth;
            // Annotation drawing on top of image
            Object.keys(annotations).forEach((categoryId) => {
                // Draw bounding box
                const { polygons } = annotations[categoryId];

                const category = categories.find(
                    ({ classId }) => categoryId === classId
                );
                if (polygons != null && category != null) {
                    const color = category.color;

                    polygons.forEach((polygon) => {
                        drawPolygon(
                            context,
                            polygon,
                            imageWidth,
                            imageHeight,
                            canvasSize,
                            color,
                            alpha
                        );
                    });
                }
            });
        };
    };

    if (imageFetchError) return <FetchErrorAlert error={imageFetchError} />;

    return (
        <Box
            onClick={onClick}
            sx={{
                width: "100%",
                height: "100%",
                transform: "rotate(90deg)",
                display: "flex",
                alignItems: "middle",
                justifyContent: "center",
            }}
        >
            {/* Display only when image is ready, I avoid using onLoad canvas event because it is
                not triggered if image is preloaded */}
            {imageLoading ? (
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="200px"
                    height="200px"
                />
            ) : (
                <Canvas
                    draw={draw}
                    width={canvasSize}
                    height={canvasSize}
                    style={{ borderRadius: 5 }}
                />
            )}
        </Box>
    );
};

SegmentedImageDisplay.propTypes = {
    image: PropTypes.string.isRequired,
    roi: PropTypes.array.isRequired,
    annotations: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    canvasSize: PropTypes.number.isRequired,
    alpha: PropTypes.number,
    onClick: PropTypes.func,
    config: PropTypes.object,
};
