/* eslint-disable camelcase */
/**
 * Settings
 */
export const APP_NAME = "Phenostation Monitoring";

/**
 * Svg logo color converter
 */
export const SVG_LOGO_FILTER = Object.freeze({
    filter: "brightness(0) saturate(100%) invert(29%) sepia(61%) saturate(4593%) hue-rotate(224deg) brightness(110%) contrast(100%)",
});

/**
 * Email handle (the part after @) regex
 */
export const EMAIL_HANDLE_REGEX =
    // eslint-disable-next-line no-control-regex
    /^(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

/**
 * UI component settings
 */
export const MIN_BIN_COUNT = 1;
export const MAX_BIN_COUNT = 100;
export const DEFAULT_BIN_COUNT = 10;
export const RANDOM_SAMPLE_COUNT_MIN = 1;
export const RANDOM_SAMPLE_COUNT_MAX = 50;
export const BIN_SAMPLE_COUNT_MIN = 1;
export const BIN_SAMPLE_COUNT_MAX = 20;
export const DEFAULT_DATE_RANGE_DAYS = 3;

export const INVALID_DATE_HELPER_TEXT = "Invalid date.";

export const ACQUISITION_DATETIME_TRAIT_NAME = "acquisition datetime";

export const PROCESSING_PROJECT_VERSION = "processing version";

export const ENTITY_SELECT_OPTION_FONT_FAMILY = "Incosolata";
export const ENTITY_SELECT_SIZE = 8;

export const GROUP_MODES = Object.freeze({
    PHENOSTATION: "Phenostation",
    ENTITY: "Entity",
    PROCESSING_VERSION: "Processing version",
});

export const PLOT_TABS = Object.freeze({
    HISTOGRAM: "histogram",
    SCATTER_PLOT: "scatterPlot",
});

export const HISTOGRAM_DIV_ID = "histogram-plot-div";

export const ENTITY_LISTS = Object.freeze({
    EVALUATION_ENTITY_LIST: "evaluationEntityList",
    SELECTED_ENTITY_LIST: "selectedEntityList",
});

export const ENTER_KEY = "Enter";

export const CHART_MARGINS = Object.freeze({ l: 40, r: 40, b: 150, t: 40 });
export const HISTOGRAM_CHART_MARGINS = Object.freeze({
    l: 40,
    r: 40,
    b: 60,
    t: 40,
});
export const CHART_MODEBAR_BUTTONS_TO_REMOVE = [
    "autoScale2d",
    "toImage",
    "zoomIn2d",
    "zoomOut2d",
];
export const CHART_HEIGHT = 700;
// z-index used by expanded section to appear above everything else
export const EXPANDED_SECTION_ZINDEX = "1101";

export const ENTITY_FETCH_WARNING_THRESHOLD = 10000;

export const MODEL_TEMPLATES = Object.freeze({
    SEMANTIC_SEGMENTATION: "semanticSegmentation",
    CLASSIFICATION: "classification",
});

export const MVR_LABELS = Object.freeze({
    MODEL: {
        fr: "Modèle",
        en: "Model",
    },
    SD_TITLE: {
        fr: "Versions précédentes du jeu étalon pour la version de modèle selectionnée",
        en: "Standard dataset previous versions for selected model version",
    },
    CONFIDENCE_SCORE: {
        fr: "Score de confiance",
        en: "Confidence score",
    },
    SELECT_IMAGES: {
        fr: "Selectionner une ou plusieurs images à visualiser",
        en: "Select one or more images to dispay",
    },
    BY_CLASS_ACC: {
        fr: "Précision par classe",
        en: "By class accuracy",
    },
});

export const LANG = "fr";

// The color name must exist in src/constants/colors.js
export const CATEGORY_COLORS = Object.freeze({
    grape: "purple",
    botrytis: "pink",
    leaf: "lime",
    unripe_berry: "yellow",
    adventice: "blue",
    colza: "yellow",
    acid_rot: "orange",
    powdery_mildew: "cyan",
});

export const MENU_LABELS = Object.freeze({
    PROJECTS: "Projects",
    MVR: "Model Validation",
});
