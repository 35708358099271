import { format } from "date-fns";

/**
 * IMPORTANT: An ISO date-only string ("yyyy-MM-dd") is not compatible with these functions,
 * since the Javascript's "new Date" will parse to UTC time while the function "format" of date-fns
 * works in local time zone.
 */

export function formatDate(date) {
    return format(new Date(date), "dd MMMM yyyy");
}

export function formatDateIsoShort(date) {
    return format(new Date(date), "yyyy-MM-dd");
}

export function formatDateTime(date) {
    return format(new Date(date), "yyyy-MM-dd HH:mm:ss");
}

export function formatDateTimeShort(date) {
    return format(new Date(date), "MM-dd HH:mm");
}
