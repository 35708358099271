import { Box, Stack, Typography } from "@mui/material";

import { History } from "@mui/icons-material";
import PropTypes from "prop-types";
import { SectionTitle } from "../../../../components/SectionTitle";
import { VersionTimelineItem } from "./VersionTimelineItem";
import { compareAsc } from "date-fns";
import { useMemo } from "react";

export const VersionTimeline = ({
    versions,
    classes,
    selectedModelVersion,
    setSelectedModelVersion,
    setComparedModelVersion,
    comparedModelVersion,
}) => {
    const sortedVersions = useMemo(
        () =>
            versions.sort((a, b) =>
                compareAsc(new Date(a.date), new Date(b.date))
            ),
        [versions]
    );

    return (
        <Box sx={{ p: 1 }}>
            <SectionTitle icon={<History />} title="Historique des versions" />
            <Stack direction="column-reverse">
                {sortedVersions.map((version, index) => (
                    <VersionTimelineItem
                        key={version.name}
                        previousVersion={versions[index - 1]}
                        classes={classes}
                        version={version}
                        versionIsSelected={
                            selectedModelVersion?.name === version.name
                        }
                        versionIsCompared={
                            comparedModelVersion?.name === version.name
                        }
                        setSelectedModelVersion={setSelectedModelVersion}
                        setComparedModelVersion={setComparedModelVersion}
                        isLatest={index === versions.length - 1}
                    />
                ))}
            </Stack>
            {setComparedModelVersion && (
                <Typography sx={{ pl: 0.5, pt: 0.5 }}>
                    <kbd>Ctrl</kbd> + <kbd>click</kbd> to compare against
                </Typography>
            )}
        </Box>
    );
};

VersionTimeline.propTypes = {
    versions: PropTypes.array.isRequired,
    setSelectedModelVersion: PropTypes.func.isRequired,
    selectedModelVersion: PropTypes.object,
};
