import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";

import { MODEL_PROPTYPES } from "../../../constants";
import PropTypes from "prop-types";

TraitValueTable.propTypes = {
    entity: PropTypes.shape(MODEL_PROPTYPES.Entity).isRequired,
    xTrait: PropTypes.string,
    yTrait: PropTypes.string,
};

export function TraitValueTable({ entity, xTrait, yTrait }) {
    return (
        <TableContainer sx={{ maxHeight: "25em", overflow: "auto" }}>
            <Table stickyHeader size="small" padding="none">
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{
                                maxWidth: "15em",
                                overflowWrap: "break-word",
                            }}
                        >
                            Trait
                        </TableCell>
                        <TableCell align="right" sx={{ pl: "1ex" }}>
                            Value
                        </TableCell>
                        <TableCell align="left" sx={{ pl: "1ex" }}>
                            Unit
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(entity.traits).map(([key, value]) => {
                        let formattedUnit = value?.unit ?? "";
                        formattedUnit =
                            formattedUnit === "NA" ? "" : formattedUnit;

                        let formattedValue = value?.value;
                        if (
                            typeof formattedValue === "number" &&
                            !Number.isInteger(formattedValue)
                        )
                            formattedValue = formattedValue.toFixed(2);

                        return (
                            <TableRow
                                key={key}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                    bgcolor:
                                        key === xTrait || key === yTrait
                                            ? "warning.light"
                                            : "",
                                }}
                            >
                                <TableCell
                                    sx={{
                                        maxWidth: "15em",
                                        overflowWrap: "break-word",
                                    }}
                                >
                                    {key}
                                </TableCell>
                                <TableCell align="right" sx={{ pl: "1ex" }}>
                                    {String(formattedValue)}
                                </TableCell>
                                <TableCell align="left" sx={{ pl: "1ex" }}>
                                    {formattedUnit}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
