import {
    BIN_SAMPLE_COUNT_MAX,
    BIN_SAMPLE_COUNT_MIN,
    PLOT_TABS,
    RANDOM_SAMPLE_COUNT_MAX,
    RANDOM_SAMPLE_COUNT_MIN,
} from "../../../constants";
import { Button, Grid, Slider } from "@mui/material";
import { Colorize, HighlightAlt } from "@mui/icons-material";
import { randomSample, samplePerBin } from "../utils/sampling";

import { Box } from "@mui/system";
import { Section } from "../../../components/Section";
import { useSnackbar } from "../../../hooks";
import { useState } from "react";

export const SelectionSection = ({
    entities,
    setEvaluationEntities,
    xTrait,
    activeTab,
}) => {
    const { openSnackbar } = useSnackbar();

    const [randomSampleCount, setRandomSampleCount] = useState(1);
    const [binSampleCount, setBinSampleCount] = useState(1);

    const binSamplingDisabled =
        !Boolean(xTrait) || activeTab !== PLOT_TABS.HISTOGRAM;
    const randomSamplingDisabled = !Boolean(xTrait);

    return (
        <Section
            icon={<Colorize />}
            title="Sampling Tools"
            popoverMessage="The sampling pool is all of the entities fetched and
        therefore selections on the plots are NOT taken into
        account."
        >
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Box sx={{ px: 2 }}>
                        <Slider
                            color="secondary"
                            disabled={binSamplingDisabled}
                            value={binSampleCount}
                            onChange={(_, value) => setBinSampleCount(value)}
                            min={BIN_SAMPLE_COUNT_MIN}
                            max={BIN_SAMPLE_COUNT_MAX}
                            marks
                        ></Slider>
                    </Box>
                    <Button
                        fullWidth
                        disabled={binSamplingDisabled}
                        type="button"
                        variant="contained"
                        onClick={() => {
                            try {
                                const sampledEntities =
                                    samplePerBin(binSampleCount);
                                setEvaluationEntities(sampledEntities);
                            } catch (error) {
                                openSnackbar(error.message, "error");
                            }
                        }}
                        color="secondary"
                        startIcon={<HighlightAlt />}
                        sx={{ textTransform: "none" }}
                    >
                        {binSampleCount} sample(s) per histogram bin
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ px: 2 }}>
                        <Slider
                            value={randomSampleCount}
                            disabled={randomSamplingDisabled}
                            onChange={(_, value) => setRandomSampleCount(value)}
                            min={RANDOM_SAMPLE_COUNT_MIN}
                            max={RANDOM_SAMPLE_COUNT_MAX}
                            marks
                            color="secondary"
                        ></Slider>
                    </Box>
                    <Button
                        fullWidth
                        type="button"
                        variant="contained"
                        disabled={randomSamplingDisabled}
                        onClick={() => {
                            const sampledEntities = randomSample(
                                entities,
                                randomSampleCount
                            );
                            setEvaluationEntities(sampledEntities);
                        }}
                        color="secondary"
                        startIcon={<HighlightAlt />}
                        sx={{ textTransform: "none" }}
                    >
                        {randomSampleCount} random sample(s)
                    </Button>
                </Grid>
            </Grid>
        </Section>
    );
};
