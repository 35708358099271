import { ModelTraining, MonitorHeart } from "@mui/icons-material";

import { FRONTEND_ROUTES } from "../../frontendRoutes";
import { MENU_LABELS } from "../../constants";

const iconSize = { width: 22, height: 22 };

const sidebarConfig = [
    {
        title: MENU_LABELS.PROJECTS,
        path: `/${FRONTEND_ROUTES.APP}/${FRONTEND_ROUTES.PROJECT}`,
        icon: <MonitorHeart sx={iconSize} />,
    },
    {
        title: MENU_LABELS.MVR,
        path: `/${FRONTEND_ROUTES.APP}/${FRONTEND_ROUTES.MODELVALIDATION}`,
        icon: <ModelTraining sx={iconSize} />,
    },
];

export default sidebarConfig;
