import { DEFAULT_ERROR_MESSAGE } from "../constants";

const fetcher = async (url, { headers, ...init } = {}) => {
    let error;
    let data;
    try {
        // Add secret to the headers
        const headersWithSecret = new Headers(headers);
        const response = await fetch(url, {
            ...init,
            headers: headersWithSecret,
        });

        // 204 is No-Content (empty body) checked first before looking at Content-Type
        if (response.status === 204) {
            data = null;
        } else {
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.includes("application/json")) {
                // If JSON conversion throws it is either not json or invalid json
                // If no body a 204 should be returned from API
                data = await response.json();
            } else {
                data = await response.blob();
            }

            // If status is not 2XX, set error object using error returned by API
            if (!response.ok) {
                error = new Error(data.message || DEFAULT_ERROR_MESSAGE);
                // Attach extra info to the error object.
                error.status = response.status;
                error.errorCode = data.errorCode;
            }
        }
    } catch {
        // If `fetch` promise rejects (i.e network error) or `response.json()` fails set error to default error message
        error = new Error(DEFAULT_ERROR_MESSAGE);
    } finally {
        // finally is always executed even if an error is thrown

        /* If error is defined throw error
            even if data is defined (i.e 4XX returned by API with error object) */
        if (error) throw error;

        // Either data returned by API or null for a 204
        return data;
    }
};

export const get = fetcher;

export const post = (url, { body, ...init } = {}) =>
    fetcher(url, {
        ...init,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
        method: "POST",
    });

export const put = (url, { body, ...init } = {}) =>
    fetcher(url, {
        ...init,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
        method: "PUT",
    });

export const patch = (url, { body, ...init } = {}) =>
    fetcher(url, {
        ...init,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
        method: "PATCH",
    });

export const destroy = (url, init = {}) =>
    fetcher(url, { ...init, method: "DELETE" });
