import { APP_NAME } from "../constants";
import { Container } from "@mui/material";
import { ModelTable } from "../features/modelValidation";
import Page from "../components/Page";

export default function Model() {
    return (
        <Page title={`Models - ${APP_NAME}`}>
            <Container maxWidth="sm">
                <ModelTable />
            </Container>
        </Page>
    );
}
