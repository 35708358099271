import { Stack, Typography } from "@mui/material";

import { APP_NAME } from "../constants";
import Logo from "../components/Logo";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";

const HeaderStyle = styled("header")(({ theme }) => ({
    top: 0,
    left: 0,
    lineHeight: 0,
    position: "absolute",
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(3, 5, 0),
    },
}));

export default function LogoOnlyLayout() {
    return (
        <>
            <HeaderStyle>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Logo />
                    <Typography
                        variant="subtitle1"
                        sx={{ color: "primary.main" }}
                    >
                        {APP_NAME}
                    </Typography>
                </Stack>
            </HeaderStyle>
            <Outlet />
        </>
    );
}
