import {
    Avatar,
    Box,
    Button,
    Divider,
    IconButton,
    Typography,
} from "@mui/material";
import { useOidc, useOidcAccessToken } from "@axa-fr/react-oidc";
import { useRef, useState } from "react";

import MenuPopover from "../../components/MenuPopover";
import { alpha } from "@mui/material/styles";

export default function AccountPopover() {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { logout } = useOidc();
    const { accessTokenPayload } = useOidcAccessToken();
    const {
        given_name: firstName,
        family_name: lastName,
        email,
    } = accessTokenPayload;

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleLogout = async () => {
        await logout();
    };

    const getInitials = (user) =>
        `${user.firstName?.[0] ? user.firstName[0] : ""}${
            user.lastName?.[0] ? user.lastName[0] : ""
        }`.toUpperCase();

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        "&:before": {
                            zIndex: 1,
                            content: "''",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            position: "absolute",
                            bgcolor: (theme) =>
                                alpha(theme.palette.grey[900], 0.72),
                        },
                    }),
                }}
            >
                <Avatar
                    sx={{ bgcolor: "primary.dark" }}
                    alt={getInitials({ firstName, lastName })}
                >
                    {getInitials({ firstName, lastName })}
                </Avatar>
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 220 }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle1" noWrap>
                        {`${firstName} ${lastName}`}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                        noWrap
                    >
                        {email}
                    </Typography>
                </Box>

                <Divider sx={{ my: 1 }} />

                <Box sx={{ p: 2, pt: 1 }}>
                    <Button
                        fullWidth
                        color="inherit"
                        variant="outlined"
                        onClick={handleLogout}
                    >
                        Logout
                    </Button>
                </Box>
            </MenuPopover>
        </>
    );
}
