import { Box, Chip, Grid, Slider, Stack, Typography } from "@mui/material";

import PropTypes from "prop-types";
import { SegmentedImageDisplay } from "../../components/DataDisplay/SegmentedImageDisplay";
import { useState } from "react";

export const ImageComparator = ({
    classes,
    modelVersionFile,
    file,
    zoomed,
    openModal,
    currentVersion,
    comparedModelVersionFile,
}) => {
    const [sliderAlpha, setSliderAlpha] = useState(0.3);
    const [annotationAlpha, setAnnotationAlpha] = useState(0.3);
    const path = file?.path;
    return (
        <Box>
            {file ? (
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Stack alignItems="center" gap={1}>
                            {/* TODO switch to annotated canvas component
                input data reformatting needed */}
                            <SegmentedImageDisplay
                                onClick={openModal}
                                config={{
                                    classes: classes,
                                }}
                                image={path}
                                roi={file.roi}
                                annotations={
                                    comparedModelVersionFile
                                        ? comparedModelVersionFile.prediction
                                        : file.annotation
                                }
                                categories={classes}
                                canvasSize={zoomed ? 800 : 250}
                                alpha={annotationAlpha}
                            />
                            {comparedModelVersionFile ? (
                                <Chip
                                    size="small"
                                    color="primary"
                                    label="Compared model"
                                />
                            ) : (
                                <Chip size="small" label="Annotation" />
                            )}
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack alignItems="center" gap={1}>
                            <SegmentedImageDisplay
                                onClick={openModal}
                                config={{
                                    classes: classes,
                                }}
                                image={path}
                                roi={file.roi}
                                annotations={modelVersionFile.prediction}
                                categories={classes}
                                canvasSize={zoomed ? 800 : 250}
                                alpha={annotationAlpha}
                            />
                            <Chip
                                size="small"
                                label={`Prediction ${
                                    currentVersion ? `(${currentVersion})` : ""
                                }`}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            ) : (
                <Typography>File not found in dataset</Typography>
            )}
            <Stack paddingTop={1} spacing={2} direction="row">
                <Typography>Opacity</Typography>
                <Slider
                    value={sliderAlpha}
                    min={0}
                    max={1}
                    step={0.01}
                    onChange={(e) => setSliderAlpha(e.target.value)}
                    onChangeCommitted={() => setAnnotationAlpha(sliderAlpha)}
                />
            </Stack>
        </Box>
    );
};

ImageComparator.propTypes = {
    classes: PropTypes.array.isRequired,
    modelVersionFile: PropTypes.object.isRequired,
    comparedModelVersionFile: PropTypes.object,
    currentVersion: PropTypes.string,
    zoomed: PropTypes.bool,
    openModal: PropTypes.func,
};
