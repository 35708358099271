import { APP_NAME } from "../constants";
import { BACKEND_ROUTES } from "../backendRoutes";
import { Container } from "@mui/material";
import { FetchErrorAlert } from "../components/FetchErrorAlert";
import { MonitoringDashboard } from "../features/monitoring/MonitoringDashboard";
import { MonitoringDashboardSkeleton } from "../features/monitoring/MonitoringDashboardSkeleton";
import Page from "../components/Page";
import { useParams } from "react-router-dom";
import useSWR from "swr";

export default function ProjectMonitor() {
    const params = useParams();
    const projectUuid = params.projectUuid;

    const { data: project, error: projectFetchError } = useSWR(
        `${BACKEND_ROUTES.PROJECT}/${projectUuid}`
    );

    if (projectFetchError) return <FetchErrorAlert error={projectFetchError} />;

    return (
        <Page title={`${project?.name} - ${APP_NAME}`}>
            <Container maxWidth={false}>
                {project ? (
                    <MonitoringDashboard project={project} />
                ) : (
                    <MonitoringDashboardSkeleton />
                )}
            </Container>
        </Page>
    );
}
