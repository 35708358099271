export const FRONTEND_ROUTES = Object.freeze({
    NOT_FOUND: "404",

    LOGIN: "login",

    APP: "app",

    PROJECT: "projects",

    MODELVALIDATION: "model-validation-report",
});
