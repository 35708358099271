import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Skeleton,
    Stack,
    Typography,
} from "@mui/material";
import { isValid, parseISO } from "date-fns";
import { useMemo, useState } from "react";

import { BACKEND_ROUTES } from "../../../backendRoutes";
import CloseIcon from "@mui/icons-material/Close";
import { FetchErrorAlert } from "../../../components/FetchErrorAlert";
import { MODEL_PROPTYPES } from "../../../constants";
import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import { formatDateTime } from "../../../utils/formatTime";
import { styled } from "@mui/material/styles";
import useSWR from "swr";

function getAcquisitionHistogramUrl(project, selectedPhenostations) {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (selectedPhenostations.length > 0) {
        let url = `${BACKEND_ROUTES.PROJECT}/${project.uuid}/${
            BACKEND_ROUTES.ACQUISITION_TIME_HISTOGRAM
        }?${selectedPhenostations
            .map((phenostation) => `phenostationUuid[]=${phenostation.uuid}`)
            .join("&")}`;

        if (userTimeZone) url += `&timeZone=${userTimeZone}`;

        return url;
    } else {
        return null;
    }
}

const Label = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontWeight: 600,
}));

const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
const selectorOptions = {
    buttons: [
        {
            step: "month",
            stepmode: "backward",
            count: 1,
            label: "1m",
        },
        {
            step: "month",
            stepmode: "backward",
            count: 6,
            label: "6m",
        },
        {
            step: "year",
            stepmode: "todate",
            count: 1,
            label: "YTD",
        },
        {
            step: "year",
            stepmode: "backward",
            count: 1,
            label: "1y",
        },
        {
            step: "all",
        },
    ],
};

AcquisitionTimeHistogramSliderDialog.propTypes = {
    project: PropTypes.shape(MODEL_PROPTYPES.Project).isRequired,
    selectedPhenostations: PropTypes.arrayOf(
        PropTypes.shape(MODEL_PROPTYPES.Phenostation)
    ).isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    initialSliderStart: PropTypes.instanceOf(Date),
    initialSliderEnd: PropTypes.instanceOf(Date),
};

/**
 * onConfirm: A function that takes two input arguments: startDatetime and endDatetime corresponding to user inputs.
 * initialSliderStart: Default start position of the slider. If not a valid date, default slider start position will be set to the first date of fetched histogram.
 * initialSliderEnd: Default end position of the slider. If not a valid date, default slider end position will be set to the last date of fetched histogram.
 */
export function AcquisitionTimeHistogramSliderDialog({
    project,
    selectedPhenostations,
    onCancel,
    onConfirm,
    initialSliderStart,
    initialSliderEnd,
}) {
    /**
     * Get acquisition time histogram of the "selected" (not "saved") phenostations
     * for the acquisition time histogram slider
     */
    const {
        data: acquisitionHistogram,
        error: acquisitionHistogramFetchError,
    } = useSWR(getAcquisitionHistogramUrl(project, selectedPhenostations), {
        revalidateOnFocus: false,
    });

    const toRender = useMemo(() => {
        if (acquisitionHistogramFetchError)
            return (
                <DialogContent>
                    <FetchErrorAlert error={acquisitionHistogramFetchError} />
                </DialogContent>
            );
        else if (acquisitionHistogram) {
            if (acquisitionHistogram.length)
                return (
                    <AcquisitionTimeHistogramSlider
                        acquisitionHistogram={acquisitionHistogram}
                        onCancel={onCancel}
                        onConfirm={onConfirm}
                        initialSliderStart={initialSliderStart}
                        initialSliderEnd={initialSliderEnd}
                    />
                );
            else
                return (
                    <DialogContent>
                        <Typography>
                            No acquisitions found for the selected
                            phenostations.
                        </Typography>
                    </DialogContent>
                );
        } else
            return (
                <DialogContent>
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height="20em"
                    />
                </DialogContent>
            );
    }, [
        acquisitionHistogram,
        acquisitionHistogramFetchError,
        onCancel,
        onConfirm,
        initialSliderStart,
        initialSliderEnd,
    ]);

    return (
        <>
            <DialogTitle>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    Acquisition time histogram slider{" "}
                    <IconButton onClick={onCancel}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            {toRender}
        </>
    );
}

/**
 * acquisitionHistogram: Should have a least one element and be already sorted by date in ascending order.
 * onConfirm: A function that takes two input arguments: startDatetime and endDatetime corresponding to user inputs.
 */
function AcquisitionTimeHistogramSlider({
    acquisitionHistogram,
    onConfirm,
    onCancel,
    initialSliderStart,
    initialSliderEnd,
}) {
    const dates = useMemo(
        () =>
            acquisitionHistogram.map(
                ({ acquisitionDay }) => new Date(acquisitionDay)
            ),
        [acquisitionHistogram]
    );

    const values = useMemo(
        () => acquisitionHistogram.map(({ count }) => count),
        [acquisitionHistogram]
    );

    // Get the datetime limits of the input data and add one day of buffer on each side
    const dataStartDatetime = useMemo(
        () => new Date(dates.at(0).getTime() - oneDayInMilliseconds),
        [dates]
    );
    const dataEndDatetime = useMemo(
        () => new Date(dates.at(-1).getTime() + oneDayInMilliseconds),
        [dates]
    );

    // Initial positions of the slider. If not a valid date, use dates from fetched histogram.
    const initialSliderStartDatetime = useMemo(
        () =>
            isValid(initialSliderStart)
                ? initialSliderStart
                : dataStartDatetime,
        [dataStartDatetime, initialSliderStart]
    );
    const initialSliderEndDatetime = useMemo(
        () => (isValid(initialSliderEnd) ? initialSliderEnd : dataEndDatetime),
        [dataEndDatetime, initialSliderEnd]
    );

    const [startDatetime, setStartDatetime] = useState(
        initialSliderStartDatetime
    );
    const [endDatetime, setEndDatetime] = useState(initialSliderEndDatetime);

    // We need to memorize layout in order to prevent Plot from re-rendering when using the slider
    const layout = useMemo(
        () => ({
            xaxis: {
                range: [initialSliderStartDatetime, initialSliderEndDatetime],
                rangeslider: {},
                rangeselector: selectorOptions,
            },
            yaxis: {
                fixedrange: true,
            },
        }),
        [initialSliderEndDatetime, initialSliderStartDatetime]
    );

    return (
        <>
            <DialogContent>
                <Plot
                    data={[
                        {
                            x: dates,
                            y: values,
                            type: "bar",
                            // Make each bar start at 0h of its day
                            offset: 0.5,
                            // Make each bar have a width of 24 hours
                            width: 24 * 60 * 60 * 1000,
                        },
                    ]}
                    layout={layout}
                    config={{
                        displaylogo: false,
                        modeBarButtonsToRemove: [
                            "select2d",
                            "lasso2d",
                            "toImage",
                        ],
                    }}
                    useResizeHandler
                    style={{ width: "100%", height: "100%" }}
                    onRelayout={(eventData) => {
                        if (eventData["xaxis.autorange"]) {
                            // These might not be exactly the same as what plotly calculate automatically
                            // but since we seem unable to get the results from plotly, we use data limits.
                            setStartDatetime(dataStartDatetime);
                            setEndDatetime(dataEndDatetime);
                        } else {
                            const xStart =
                                eventData?.["xaxis.range"]?.[0] ||
                                eventData?.["xaxis.range[0]"];
                            if (xStart) setStartDatetime(parseISO(xStart));

                            const xEnd =
                                eventData?.["xaxis.range"]?.[1] ||
                                eventData?.["xaxis.range[1]"];
                            if (xEnd) setEndDatetime(parseISO(xEnd));
                        }
                    }}
                />
                <Stack direction="row" spacing={2}>
                    <Label>Start datetime: </Label>
                    <Typography>{formatDateTime(startDatetime)}</Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Label>End datetime: </Label>
                    <Typography>{formatDateTime(endDatetime)}</Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    onClick={() => onConfirm(startDatetime, endDatetime)}
                >
                    Confirm
                </Button>
            </DialogActions>
        </>
    );
}
