export const buttonStyleOverride = {
    MuiButton: {
        styleOverrides: {
            root: {
                "&:hover": {
                    boxShadow: "none",
                },
            },
            sizeLarge: {
                height: 48,
            },
            containedInherit: ({ theme }) => ({
                color: theme.palette.grey[800],
                boxShadow: theme.customShadows.z8,
                "&:hover": {
                    backgroundColor: theme.palette.grey[400],
                },
            }),
            containedPrimary: ({ theme }) => ({
                boxShadow: theme.customShadows.primary,
            }),
            containedSecondary: ({ theme }) => ({
                boxShadow: theme.customShadows.secondary,
            }),
            outlinedInherit: ({ theme }) => ({
                border: `1px solid ${theme.palette.grey[500_32]}`,
                "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                },
            }),
            textInherit: ({ theme }) => ({
                "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                },
            }),
        },
    },
};
