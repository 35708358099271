/**
 * Api limits
 */
export const PAGINATION = Object.freeze({
    GENERIC: {
        LIMIT: {
            MAX: 2000,
        },
    },
});

/**
 * API error codes
 * These have to be identical as the values in the backend.
 */
export const ERROR_CODES = Object.freeze({
    PASSWORD_CHANGE_REQUIRED: 3,
});
