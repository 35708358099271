import { APP_NAME } from "../constants";
import { Container } from "@mui/material";
import Page from "../components/Page";
import { ProjectTable } from "../features/project";

export default function Project() {
    return (
        <Page title={`Projects - ${APP_NAME}`}>
            <Container maxWidth="sm">
                <ProjectTable />
            </Container>
        </Page>
    );
}
