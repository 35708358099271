import { Autocomplete, Stack, TextField } from "@mui/material";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Section } from "../../../components/Section";

export const FilterSection = ({
    processingVersionList,
    tagList,
    selectedTags,
    setSelectedTags,
    selectedProcessingVersions,
    setSelectedProcessingVersions,
}) => {
    return (
        <Section icon={<FilterAltIcon />} title="Filters">
            <Stack gap={1}>
                <Autocomplete
                    multiple
                    size="small"
                    filterSelectedOptions
                    value={selectedProcessingVersions}
                    onChange={(_, value) => {
                        setSelectedProcessingVersions(value);
                    }}
                    options={processingVersionList}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Processing version filter"
                            placeholder="Select processing versions"
                        />
                    )}
                />
                <Autocomplete
                    multiple
                    size="small"
                    filterSelectedOptions
                    value={selectedTags}
                    onChange={(_, value) => {
                        setSelectedTags(value);
                    }}
                    options={tagList}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Tags"
                            placeholder="Select tags"
                        />
                    )}
                />
            </Stack>
        </Section>
    );
};
