import PropTypes from "prop-types";

/**
 * Basic models
 */
const Company = Object.freeze({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

const Project = Object.freeze({
    uuid: PropTypes.string.isRequired,
    companyUuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

const Phenostation = Object.freeze({
    uuid: PropTypes.string.isRequired,
    projectUuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    acquisitionUnitId: PropTypes.string.isRequired,
    processingUnitId: PropTypes.string.isRequired,
});

const Acquisition = Object.freeze({
    uuid: PropTypes.string.isRequired,
    phenostationUuid: PropTypes.string.isRequired,
    acquisitionRef: PropTypes.string.isRequired,
    acquisitionDatetime: PropTypes.instanceOf(Date).isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    payload: PropTypes.object.isRequired,
});

const Entity = Object.freeze({
    uuid: PropTypes.string.isRequired,
    acquisitionUuid: PropTypes.string.isRequired,
    entityRef: PropTypes.string.isRequired,
    processingProjectVersion: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    traits: PropTypes.object.isRequired,
});

const File = Object.freeze({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    complete: PropTypes.bool.isRequired,
});

const AcquisitionFile = Object.freeze({
    uuid: PropTypes.string.isRequired,
    acquisitionUuid: PropTypes.string.isRequired,
    fileUuid: PropTypes.string.isRequired,
});

const EntityFile = Object.freeze({
    uuid: PropTypes.string.isRequired,
    entityUuid: PropTypes.string.isRequired,
    fileUuid: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    roi: PropTypes.arrayOf(PropTypes.number),
    annotation: PropTypes.object,
});

/**
 * Eager-loaded models
 */
const EntityWithParents = Object.freeze({
    ...Entity,
    Acquisition: PropTypes.shape({
        ...Acquisition,
        Phenostation: PropTypes.shape(Phenostation).isRequired,
    }).isRequired,
});

export const MODEL_PROPTYPES = Object.freeze({
    Company,
    Project,
    Phenostation,
    Acquisition,
    Entity,
    File,
    AcquisitionFile,
    EntityFile,
    EntityWithParents,
});
