const MAIN_ROOT = process.env.REACT_APP_API_URL;
const USERS_API_ROOT = `${MAIN_ROOT}/users`;

export const BACKEND_ROUTES = {
    MY_INFO: `${USERS_API_ROOT}/my-info`,

    COMPANY: `${MAIN_ROOT}/companies`,
    PROJECT: `${MAIN_ROOT}/projects`,
    PHENOSTATION: `${MAIN_ROOT}/phenostations`,
    ACQUISITION: `${MAIN_ROOT}/acquisitions`,
    ENTITY: `${MAIN_ROOT}/entities`,
    FILES: `${MAIN_ROOT}/files`,
    DEEP_LEARNING_MODELS: `${MAIN_ROOT}/deep-learning-models`,

    PROJECT_ENTITIES: "entities",
    ACQUISITION_TIME_HISTOGRAM: "acquisition-time-histogram",
    ENTITY_ENTITY_FILES: "entity-files",
    FILE_URL: "url",
};
