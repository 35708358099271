import { APPBAR_HEIGHT_MOBILE } from "./constants";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useState } from "react";

const RootStyle = styled("div")({
    display: "flex",
    minHeight: "100%",
    overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%",
    paddingTop: APPBAR_HEIGHT_MOBILE,
    paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
}));

export default function DashboardLayout() {
    const [open, setOpen] = useState(false);
    return (
        <RootStyle>
            <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
            <DashboardSidebar
                isOpenSidebar={open}
                onCloseSidebar={() => setOpen(false)}
            />
            <MainStyle>
                <Outlet />
            </MainStyle>
        </RootStyle>
    );
}
