import { range, shuffle } from "d3-array";

import { HISTOGRAM_DIV_ID } from "../../../constants";

/**
 * Use the results of Plotly's histogram to randomly sample from each bin.
 * @param {number} [sampleCountPerBin = 5] - Number of entities to sample in each bin.
 * @returns {Array} An array of sampled entities.
 * @throws - If histogram div is not found (by id) or its data is unusable, an error is thrown.
 */
export function samplePerBin(sampleCountPerBin = 5) {
    const histogramDiv = document.getElementById(HISTOGRAM_DIV_ID);

    if (
        histogramDiv &&
        Array.isArray(histogramDiv.calcdata) &&
        Array.isArray(histogramDiv.data)
    ) {
        const bins = [];

        const curveCount = histogramDiv.calcdata.length;
        const binCount = curveCount ? histogramDiv.calcdata[0].length : 0;

        for (let binIdx = 0; binIdx < binCount; binIdx++) {
            let bin = [];

            for (let curveIdx = 0; curveIdx < curveCount; curveIdx++) {
                bin = bin.concat(
                    histogramDiv.calcdata[curveIdx][binIdx].pts.map(
                        (pointIdx) =>
                            histogramDiv.data[curveIdx].customdata[pointIdx]
                    )
                );
            }

            bins.push(bin);
        }

        return bins.flatMap((bin) => {
            const binLength = bin.length;
            if (binLength <= sampleCountPerBin) {
                return bin;
            } else {
                const shuffledIndices = shuffle(range(binLength));
                const sampleIndices = shuffledIndices.slice(
                    0,
                    sampleCountPerBin
                );
                return sampleIndices.map((index) => bin[index]);
            }
        });
    } else {
        throw new Error("Error: histogram data not found");
    }
}

/**
 * Get random N samples of an array without replacement.
 * @param {Array} entities - The array to sample.
 * @param {number} sampleCount - The number of random elements to sample.
 * @returns {Array} Array of samples.
 */
export function randomSample(entities, sampleCount) {
    const entityCount = entities.length;
    if (entityCount <= sampleCount) {
        return entities;
    } else {
        const shuffledIndices = shuffle(range(entityCount));
        const sampleIndices = shuffledIndices.slice(0, sampleCount);
        return sampleIndices.map((index) => entities[index]);
    }
}
