/* eslint-disable camelcase */
import { LANG, MVR_LABELS } from "../../../../constants";

import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

export const Histogram = ({ data }) => {
    const theme = useTheme();
    const plotableData = [
        {
            x: data,
            type: "histogram",
            hovertemplate:
                "<b>%{y:}</b> images classified with a confidence score of <b>%{x:}</b><extra></extra>",
            marker: {
                color: theme.palette.primary.main,
            },
        },
    ];
    return (
        <Plot
            data={plotableData}
            layout={{
                margin: { l: 40, r: 20, t: 20, b: 40 },
                xaxis: {
                    title: MVR_LABELS.CONFIDENCE_SCORE[LANG],
                    rangemode: "tozero",
                    fixedrange: true,
                },
                yaxis: { title: "Image count", fixedrange: true },
                plot_bgcolor: "transparent",
                paper_bgcolor: "transparent",
            }}
            config={{ displayModeBar: false }}
        />
    );
};

Histogram.propTypes = {
    data: PropTypes.array.isRequired,
};
