import { useEffect, useRef } from "react";

import PropTypes from "prop-types";

export const Canvas = (props) => {
    const canvasRef = useRef(null);

    const { draw, ...other } = props;

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        draw(context);
    }, [draw]);

    return <canvas ref={canvasRef} {...other} />;
};

Canvas.propTypes = {
    draw: PropTypes.func.isRequired,
};
