import { ACCESS_ROLE, RESOURCE_ACCESS } from "../../constants/index";
import { OidcSecure, useOidcAccessToken } from "@axa-fr/react-oidc";

import PageAccessRefused from "../../pages/PageAccessRefused";
import { useMemo } from "react";

export const RequireAuth = ({ children }) => {
    const { accessTokenPayload } = useOidcAccessToken();
    const userHasAccess = useMemo(
        () =>
            accessTokenPayload?.resource_access[
                RESOURCE_ACCESS
            ]?.roles.includes(ACCESS_ROLE),
        [accessTokenPayload?.resource_access]
    );

    return (
        <OidcSecure>
            {userHasAccess ? children : <PageAccessRefused />}
        </OidcSecure>
    );
};
