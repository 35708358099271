import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";

import PropTypes from "prop-types";

export function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
                size={props.size ?? 50}
                variant="determinate"
                {...props}
            />
            <Tooltip title={props.title}>
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {!props.hideLabel && (
                        <Typography
                            fontSize="small"
                            component="div"
                            color="text.secondary"
                        >
                            {`${Math.round(props.value)}%`}
                        </Typography>
                    )}
                </Box>
            </Tooltip>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    size: PropTypes.number,
    hideLabel: PropTypes.bool,
};
