import { Popover, Typography } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import PropTypes from "prop-types";
import { useState } from "react";

InfoPopover.propTypes = {
    msg: PropTypes.string.isRequired,
};

/**
 * A popover icon for explaining the sampling pool
 * @param {string} props.msg - Message to display into the info popover.
 * @returns {ReactComponent}
 */

//
export function InfoPopover({ msg }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <>
            <InfoIcon
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            ></InfoIcon>
            <Popover
                sx={{ pointerEvents: "none" }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1, maxWidth: 200 }}>{msg}</Typography>
            </Popover>
        </>
    );
}
