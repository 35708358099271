import { Card, Typography } from "@mui/material";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { DataGrid } from "@mui/x-data-grid";
import { FRONTEND_ROUTES } from "../../frontendRoutes";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { PAGINATION } from "../../constants";
import { ProjectTableSkeleton } from "./ProjectTableSkeleton";
import Scrollbar from "../../components/Scrollbar";
import { useNavigate } from "react-router-dom";
import { useSWRAllPages } from "../../hooks/useSWRAllPages";

// DataGrid column definitions
const gridColDef = [
    {
        field: "name",
        headerName: "Name",
        minWidth: 300,
        flex: 3,
    },
    {
        field: "company",
        headerName: "Company",
        maxWidth: 100,
        flex: 1,
    },
];

export function ProjectTable() {
    const navigate = useNavigate();

    // Fetch projects
    const { data: projectsData, error: projectsFetchError } = useSWRAllPages(
        () => `${BACKEND_ROUTES.PROJECT}?parentInfo=true`,
        PAGINATION.GENERIC.LIMIT.MAX
    );

    if (projectsFetchError)
        return <FetchErrorAlert error={projectsFetchError} />;
    if (!projectsData) return <ProjectTableSkeleton />;

    const projects = projectsData;

    const gridRows = projects.map((project) => {
        return {
            id: project.uuid,
            uuid: project.uuid,
            name: project.name,
            company: project.Company.name,
        };
    });

    return (
        <>
            <Typography
                variant="h6"
                sx={{ color: "text.secondary" }}
                gutterBottom
            >
                Phenostation Projects
            </Typography>

            <Card>
                <Scrollbar>
                    <DataGrid
                        autoHeight
                        rows={gridRows}
                        columns={gridColDef}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "name", sort: "asc" }],
                            },
                        }}
                        onRowClick={({ row }) => {
                            navigate(
                                `/${FRONTEND_ROUTES.APP}/${FRONTEND_ROUTES.PROJECT}/${row.uuid}`
                            );
                        }}
                        sx={{
                            "& .MuiDataGrid-row": {
                                cursor: "pointer",
                            },
                        }}
                    />
                </Scrollbar>
            </Card>
        </>
    );
}
