import { APP_NAME, PAGINATION } from "../../constants";
import { Box, Drawer, Stack, Typography } from "@mui/material";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { DRAWER_WIDTH } from "./constants";
import Logo from "../../components/Logo";
import NavSection from "../../components/NavSection";
import PropTypes from "prop-types";
import Scrollbar from "../../components/Scrollbar";
import sidebarConfig from "./sidebarConfig";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSWRAllPages } from "../../hooks/useSWRAllPages";

DashboardSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
    const { pathname } = useLocation();

    const { data: modelsData } = useSWRAllPages(
        () => `${BACKEND_ROUTES.DEEP_LEARNING_MODELS}`,
        PAGINATION.GENERIC.LIMIT.MAX
    );

    const hideMvr = !modelsData?.length;

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                "& .simplebar-content": {
                    height: 1,
                    display: "flex",
                    flexDirection: "column",
                },
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ px: 2.5, py: 3 }}
            >
                <Logo />
                <Typography variant="subtitle2" sx={{ color: "primary.main" }}>
                    {APP_NAME}
                </Typography>
            </Stack>

            <NavSection navConfig={sidebarConfig} hideMvr={hideMvr} />

            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    );

    return (
        <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{
                sx: { width: DRAWER_WIDTH },
            }}
        >
            {renderContent}
        </Drawer>
    );
}
