import { Box, Container, Link, Typography } from "@mui/material";
import { MotionContainer, varBounceIn } from "../components/animate";

import { HIPHEN_CONTACT_US_PAGE } from "../constants";
import Page from "../components/Page";
import { motion } from "framer-motion";
import { styled } from "@mui/material/styles";

const RootStyle = styled(Page)(({ theme }) => ({
    display: "flex",
    minHeight: "100%",
    alignItems: "center",
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10),
}));

export default function PageAccessRefused() {
    return (
        <RootStyle title="Access denied">
            <Container>
                <MotionContainer initial="initial" open>
                    <Box
                        sx={{
                            maxWidth: 480,
                            margin: "auto",
                            textAlign: "center",
                        }}
                    >
                        <motion.div variants={varBounceIn}>
                            <Typography variant="h3" paragraph>
                                Access denied
                            </Typography>
                        </motion.div>
                        <Typography sx={{ color: "text.secondary" }}>
                            You do not have the permission to access HCC
                            Phenostation. Contact Hiphen for support
                        </Typography>

                        <Link
                            underline="none"
                            variant="subtitle2"
                            href={HIPHEN_CONTACT_US_PAGE}
                        >
                            Contact us
                        </Link>
                    </Box>
                </MotionContainer>
            </Container>
        </RootStyle>
    );
}
