import { Grid, Skeleton } from "@mui/material";

export const MonitoringDashboardSkeleton = () => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="15%"
                    height="2em"
                />
            </Grid>
            <Grid item xs={2}>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height="30em"
                />
            </Grid>
            <Grid item xs={5}>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height="30em"
                />
            </Grid>
            <Grid item xs={5}>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height="30em"
                />
            </Grid>
        </Grid>
    );
};
