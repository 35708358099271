import { Grid, Typography } from "@mui/material";

import PropTypes from "prop-types";

export const SectionTitle = ({ icon, title }) => {
    return (
        <Grid container spacing={0.5}>
            <Grid item>{icon}</Grid>
            <Grid item>
                <Typography variant="h6">{title}</Typography>
            </Grid>
        </Grid>
    );
};

SectionTitle.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.string,
};
