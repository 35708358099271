import {
    Box,
    Card,
    Chip,
    Grid,
    Modal,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

import { Image } from "@mui/icons-material";
import { ImageComparator } from "./ImageComparator";
import PropTypes from "prop-types";
import { useState } from "react";

export const ModelVersionFile = ({
    classes,
    file,
    currentVersion,
    modelVersionFile,
    comparedModelVersionFile,
    versionDataIsValidating,
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    return modelVersionFile ? (
        <>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ p: 2 }}
            >
                <Box>
                    <Card sx={{ p: 2 }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <ImageComparator
                                    zoomed
                                    classes={classes}
                                    modelVersionFile={modelVersionFile}
                                    file={file}
                                    comparedModelVersionFile={
                                        comparedModelVersionFile
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Box>
            </Modal>
            {file ? (
                <Stack gap={1}>
                    {/* File header */}
                    <Paper sx={{ flexGrow: 1, p: 1 }} elevation={3}>
                        <Typography fontSize="small">{file.date}</Typography>
                        <Stack direction="row" alignItems="center">
                            <Image fontSize="small" />
                            <Typography fontSize="small">
                                {modelVersionFile?.imageName}
                            </Typography>
                        </Stack>
                        <Typography fontSize="small" fontStyle="italic">
                            {file.message}
                        </Typography>
                        <Stack direction="row" alignItems="center" gap={0.5}>
                            {file.tags.map((tag) => (
                                <Chip
                                    label={tag}
                                    size="small"
                                    sx={{ borderRadius: 1 }}
                                />
                            ))}
                        </Stack>
                    </Paper>
                    <ImageComparator
                        openModal={() => setModalOpen(true)}
                        classes={classes}
                        file={file}
                        modelVersionFile={modelVersionFile}
                        currentVersion={currentVersion}
                        comparedModelVersionFile={comparedModelVersionFile}
                    />
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    {classes.map(({ name }) => (
                                        <TableCell key={name}>{name}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.entries(
                                    modelVersionFile.evaluationMetrics
                                ).map(([metric, row]) => (
                                    <TableRow key={metric}>
                                        <TableCell>{metric}</TableCell>
                                        {classes.map(({ classId }) => (
                                            <TableCell key={classId}>
                                                {row[classId]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            ) : (
                <Typography>Image file not found in dataset</Typography>
            )}
        </>
    ) : versionDataIsValidating ? (
        <Typography>Loading...</Typography>
    ) : (
        <Typography>Model version file not found</Typography>
    );
};

ModelVersionFile.propTypes = {
    classes: PropTypes.array.isRequired,
    currentVersion: PropTypes.string,
    modelVersionFile: PropTypes.object,
    versionDataIsValidating: PropTypes.bool,
    comparedModelVersionFile: PropTypes.object,
};
