export const DEFAULT_ERROR_MESSAGE =
    "An error occurred, please try again in a few minutes.";
export const NOT_FOUND_ERROR_MESSAGE = "Not found.";

/**
 * Validation messages
 */
export const INPUT_VALIDATION = Object.freeze({
    REQUIRED: "This field is required.",
    INVALID: "This input is invalid.",
    EMAIL_INVALID: "Please enter a valid email handle.",
});
