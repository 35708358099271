import { Card, Typography } from "@mui/material";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { DataGrid } from "@mui/x-data-grid";
import { FRONTEND_ROUTES } from "../../frontendRoutes";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { ModelTableSkeleton } from "./ModelTableSkeleton";
import { PAGINATION } from "../../constants";
import Scrollbar from "../../components/Scrollbar";
import { useNavigate } from "react-router-dom";
import { useSWRAllPages } from "../../hooks/useSWRAllPages";

// DataGrid column definitions
const gridColDef = [
    {
        field: "name",
        headerName: "Name",
        minWidth: 300,
        flex: 3,
    },
];

export function ModelTable() {
    const navigate = useNavigate();

    // Fetch projects
    const { data: modelsData, error: modelsFetchError } = useSWRAllPages(
        () => `${BACKEND_ROUTES.DEEP_LEARNING_MODELS}`,
        PAGINATION.GENERIC.LIMIT.MAX
    );

    if (modelsFetchError) return <FetchErrorAlert error={modelsFetchError} />;
    if (!modelsData) return <ModelTableSkeleton />;

    const models = modelsData;

    const gridRows = models.map((model) => {
        return {
            id: model.uuid,
            uuid: model.uuid,
            name: model.name,
        };
    });

    return (
        <>
            <Typography variant="h4" gutterBottom>
                Models
            </Typography>

            <Card>
                <Scrollbar>
                    <DataGrid
                        autoHeight
                        rows={gridRows}
                        columns={gridColDef}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "name", sort: "asc" }],
                            },
                        }}
                        onRowClick={({ row }) => {
                            navigate(
                                `/${FRONTEND_ROUTES.APP}/${FRONTEND_ROUTES.MODELVALIDATION}/${row.uuid}`
                            );
                        }}
                        sx={{
                            "& .MuiDataGrid-row": {
                                cursor: "pointer",
                            },
                        }}
                    />
                </Scrollbar>
            </Card>
        </>
    );
}
