import { LANG, MVR_LABELS } from "../../../../constants";

import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import { useMemo } from "react";

export const PerformanceScatter = ({
    data,
    dataset,
    comparedVersionData,
    classes,
    surfaceMode,
    handleImageSelection,
    selectedModelVersion,
}) => {
    const plotData = useMemo(() => {
        let maximum = 0;
        return [
            ...classes.map(({ classId, name, color }) => {
                const xData = [];
                const yData = [];

                data.forEach(({ imageName, prediction }) => {
                    let x;
                    if (comparedVersionData) {
                        x = comparedVersionData.ModelVersionFiles.find(
                            ({ imageName: comparedImageName }) =>
                                comparedImageName === imageName
                        )?.prediction[classId];
                    } else {
                        const DeeplearningModelFile = dataset.find(
                            ({ name }) => name === imageName
                        );
                        x = DeeplearningModelFile?.annotation[classId];
                    }
                    const xv = surfaceMode ? x?.cover : x?.count;
                    maximum = xv > maximum ? xv : maximum;
                    xData.push(xv);

                    const y = prediction[classId];
                    const yv = surfaceMode ? y?.cover : y?.count;
                    maximum = yv > maximum ? yv : maximum;
                    yData.push(yv);
                });

                return {
                    name: name,
                    type: "scatter",
                    mode: "markers",

                    x: xData,
                    y: yData,
                    marker: { color: color },
                    hovertext: data.map(({ imageName }) => imageName),
                    hovertemplate: `<extra></extra><b>Image Name:</b> %{hovertext}<br>${
                        comparedVersionData?.name ?? "Annotation"
                    }: %{x}<br>${selectedModelVersion?.name}: %{y}`,
                };
            }),
            // Straight diagonal line
            {
                type: "scatter",
                mode: "lines",
                hoverinfo: "skip",
                x: [0, maximum],
                y: [0, maximum],
                line: { color: "#00000055", dash: "dot" },
            },
        ];
    }, [
        classes,
        comparedVersionData,
        data,
        dataset,
        selectedModelVersion?.name,
        surfaceMode,
    ]);

    return (
        <Plot
            onSelected={(event) => {
                const points = event?.points;
                if (points?.length)
                    handleImageSelection(
                        Array.from(
                            new Set(points?.map((point) => point.hovertext))
                        )
                    );
            }}
            data={plotData}
            layout={{
                title: "Validation sur le jeu étalon",
                hovermode: "closest",
                dragmode: "select",
                showlegend: false,
                xaxis: {
                    title: comparedVersionData
                        ? `${MVR_LABELS.MODEL[LANG]} ${comparedVersionData?.name}`
                        : "Annotation",
                },
                yaxis: {
                    title: `${MVR_LABELS.MODEL[LANG]} ${selectedModelVersion?.name}`,
                },
                margin: { l: 70, r: 20, t: 60, b: 40 },
            }}
            style={{ width: "95%", height: "100%" }}
            useResizeHandler
            config={{ responsive: true, displayModeBar: false }}
        />
    );
};

PerformanceScatter.propTypes = {
    data: PropTypes.array.isRequired,
    classes: PropTypes.array.isRequired,
    surfaceMode: PropTypes.bool.isRequired,
    handleImageSelection: PropTypes.func.isRequired,
    selectedModelVersion: PropTypes.object.isRequired,
};
