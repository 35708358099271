import { Card, IconButton, Stack } from "@mui/material";
import { CloseFullscreen, OpenInFull } from "@mui/icons-material";
import { useEffect, useState } from "react";

import { EXPANDED_SECTION_ZINDEX } from "../constants";
import { InfoPopover } from "./InfoPopover";
import PropTypes from "prop-types";
import { SectionTitle } from "./SectionTitle";

const expandedStyles = {
    p: 1,
    position: "fixed",
    left: 0,
    top: 0,
    width: "100vw",
    height: "100vh",
    zIndex: EXPANDED_SECTION_ZINDEX,
};

export const Section = ({
    cardStyles,
    children,
    icon,
    title,
    height,
    popoverMessage,
    expandable,
}) => {
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        // Dispatch resize event to force plotly resize on graphs
        window.dispatchEvent(new Event("resize"));
    }, [expanded]);

    return (
        <Card
            sx={{
                ...(expanded ? expandedStyles : { p: 1, height: height }),
                ...cardStyles,
            }}
        >
            <Stack direction="row">
                <SectionTitle icon={icon} title={title} />
                {popoverMessage && <InfoPopover msg={popoverMessage} />}
                {expandable && (
                    <IconButton
                        size="small"
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? (
                            <CloseFullscreen fontSize="small" />
                        ) : (
                            <OpenInFull fontSize="small" />
                        )}
                    </IconButton>
                )}
            </Stack>
            {children}
        </Card>
    );
};

Section.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.string,
    height: PropTypes.number,
    popoverMessage: PropTypes.string,
    children: PropTypes.any,
    expandable: PropTypes.bool,
    cardStyles: PropTypes.object,
};
