import { Navigate, useRoutes } from "react-router-dom";

import DashboardLayout from "./layouts/dashboard";
import { FRONTEND_ROUTES } from "./frontendRoutes";
import Login from "./pages/Login";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import Model from "./pages/Model";
import NotFound from "./pages/Page404";
import Project from "./pages/Project";
import ProjectMonitor from "./pages/ProjectMonitor";
import { RequireAuth } from "./context/auth/RequireAuth";
import { ValidationReport } from "./features/modelValidation";

export default function Router() {
    return useRoutes([
        {
            path: FRONTEND_ROUTES.APP,
            element: (
                <RequireAuth>
                    <DashboardLayout />
                </RequireAuth>
            ),
            children: [
                {
                    index: true,
                    element: <Navigate to={FRONTEND_ROUTES.PROJECT} />,
                },
                {
                    path: `${FRONTEND_ROUTES.PROJECT}/`, // Display all user projects
                    element: <Project />,
                },
                {
                    path: FRONTEND_ROUTES.MODELVALIDATION,
                    element: <Model />,
                },
                {
                    path: `${FRONTEND_ROUTES.MODELVALIDATION}/:modelUuid`,
                    element: <ValidationReport />,
                },
                {
                    path: `${FRONTEND_ROUTES.PROJECT}/:projectUuid`,
                    element: <ProjectMonitor />,
                },
            ],
        },
        {
            path: "/",
            element: <LogoOnlyLayout />,
            children: [
                {
                    path: "/",
                    element: <Navigate to={`${FRONTEND_ROUTES.APP}`} />,
                },
                {
                    path: FRONTEND_ROUTES.LOGIN,
                    element: <Login />,
                },
                { path: FRONTEND_ROUTES.NOT_FOUND, element: <NotFound /> },
            ],
        },
        {
            path: "*",
            element: <Navigate to={`/${FRONTEND_ROUTES.NOT_FOUND}`} replace />,
        },
    ]);
}
