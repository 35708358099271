import {
    Grid,
    Paper,
    Stack,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";

import { ChevronRightRounded } from "@mui/icons-material";
import { CircularProgressWithLabel } from "../../components/CircularProgressWithLabel";
import { Fragment } from "react";
import PropTypes from "prop-types";

const MatrixPaper = ({
    value,
    rowIndex,
    colIndex,
    selectedCategoryIndexes,
    classes,
    handleBoxClick,
}) => {
    const disabled = value === 0;
    const selected =
        selectedCategoryIndexes[1] === rowIndex &&
        selectedCategoryIndexes[0] === colIndex;
    const theme = useTheme();

    return (
        <Tooltip
            title={`${classes[colIndex].name} classifiées en tant que ${classes[rowIndex].name} (${value})`}
        >
            <Paper
                elevation={disabled ? 1 : 3}
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    justifyContent: "center",
                    aspectRatio: "1/1",
                    userSelect: "none",
                    cursor: disabled ? "auto" : "pointer",
                    fontWeight: rowIndex === colIndex ? "bold" : "auto",
                    outline: selected
                        ? `2px solid ${theme.palette.primary.main}`
                        : "none",
                    opacity: disabled ? ".5" : 1,
                }}
                onClick={() => {
                    if (!disabled) handleBoxClick([colIndex, rowIndex]);
                }}
            >
                {value}
            </Paper>
        </Tooltip>
    );
};

export const ClassificationMatrix = ({
    matrixData,
    classes,
    handleBoxClick,
    selectedCategoryIndexes,
}) => {
    return (
        <Grid container sx={{ p: 2 }}>
            <Grid item xs={6}>
                <Stack direction="row" sx={{ gap: 1, flexGrow: 1 }}>
                    {classes.map((c) => (
                        <Typography
                            key={c.name}
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                                justifyContent: "center",
                                userSelect: "none",
                                cursor: "pointer",
                                overflow: "hidden",
                            }}
                        >
                            {c.name}
                        </Typography>
                    ))}
                </Stack>
            </Grid>
            <Grid item xs={6} />
            {matrixData.map((row, rowIndex) => {
                const total = row.reduce((a, b) => a + b, 0);
                const accuracy =
                    total && matrixData[rowIndex][rowIndex] / total;

                const gridItemRatio = 6 / matrixData.length;
                return (
                    <Fragment key={`r_${rowIndex}`}>
                        {row.map((value, colIndex) => (
                            <Grid
                                key={`${colIndex}_${rowIndex}`}
                                item
                                xs={gridItemRatio}
                                sx={{ p: 0.5 }}
                            >
                                <MatrixPaper
                                    value={value}
                                    colIndex={colIndex}
                                    rowIndex={rowIndex}
                                    selectedCategoryIndexes={
                                        selectedCategoryIndexes
                                    }
                                    classes={classes}
                                    handleBoxClick={handleBoxClick}
                                />
                            </Grid>
                        ))}
                        <Grid
                            item
                            xs={6}
                            sx={{
                                flexGrow: 1,
                                display: "flex",
                                alignItems: "center",
                                p: 1,
                            }}
                        >
                            <Stack
                                direction="row"
                                sx={{
                                    flexGrow: 1,
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Stack direction="row">
                                    <ChevronRightRounded />
                                    <Typography>
                                        {classes[rowIndex].name}
                                    </Typography>
                                </Stack>
                                <CircularProgressWithLabel
                                    value={accuracy * 100}
                                    title={`${accuracy * 100}% of ${
                                        classes[rowIndex].name
                                    } classified were correct`}
                                />
                            </Stack>
                        </Grid>
                    </Fragment>
                );
            })}
            {/* SECOND ROW */}
            <Grid item xs={6}>
                <Typography
                    sx={{
                        width: 1,
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                    }}
                >
                    Classe attendue
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography
                    sx={{
                        width: 1,
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                    }}
                >
                    Classe détéctée
                </Typography>
            </Grid>
        </Grid>
    );
};

MatrixPaper.propTypes = {
    value: PropTypes.number.isRequired,
    rowIndex: PropTypes.number.isRequired,
    colIndex: PropTypes.number.isRequired,
    classes: PropTypes.array.isRequired,
    handleBoxClick: PropTypes.func.isRequired,
    selectedCategoryIndexes: PropTypes.array,
};

ClassificationMatrix.propTypes = {
    matrixData: PropTypes.array.isRequired,
    classes: PropTypes.array.isRequired,
    handleBoxClick: PropTypes.func.isRequired,
    selectedCategoryIndexes: PropTypes.array.isRequired,
};
