import { Chip, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { Circle, Output } from "@mui/icons-material";

import PropTypes from "prop-types";
import { Section } from "../../../../components/Section";

export const ModelIdCard = ({
    model,
    filteredOutClasses,
    handleFilterClass,
}) => {
    const { name, architecture, description, classes, outputFormat } = model;

    return (
        <Section>
            <Stack gap={1}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack
                        direction="row"
                        sx={{ gap: 3, alignItems: "baseline" }}
                    >
                        <Typography variant="h4">{name}</Typography>
                        <Typography variant="subtitle1">
                            {architecture}
                        </Typography>
                    </Stack>
                    <Chip icon={<Output />} label={outputFormat}></Chip>
                </Stack>
                <Divider />

                <Typography variant="body1">{description}</Typography>
                <Stack direction="row" gap={1}>
                    {classes.map(({ name, description, classId, color }) => (
                        <Tooltip
                            key={name}
                            title={`Class ${name}: ${description}`}
                        >
                            <Chip
                                icon={
                                    <Circle
                                        fontSize="smaller"
                                        sx={{
                                            color: `
                                                ${
                                                    filteredOutClasses.includes(
                                                        classId
                                                    )
                                                        ? "lightgray"
                                                        : color
                                                } !important`,
                                        }}
                                    />
                                }
                                variant="outlined"
                                label={name}
                                onClick={() => handleFilterClass(classId)}
                            />
                        </Tooltip>
                    ))}
                </Stack>
            </Stack>
        </Section>
    );
};

ModelIdCard.propTypes = {
    model: PropTypes.object.isRequired,
    filteredOutClasses: PropTypes.array.isRequired,
    handleFilterClass: PropTypes.func.isRequired,
};
