import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { LANG, MVR_LABELS } from "../../../../constants";

import PropTypes from "prop-types";
import { useState } from "react";

export const ImageCarousel = ({ images, renderItem }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        if (currentIndex === images.length - 1) setCurrentIndex(0);
        else setCurrentIndex(currentIndex + 1);
    };
    const handlePrev = () => {
        if (currentIndex === 0) setCurrentIndex(images.length - 1);
        else setCurrentIndex(currentIndex - 1);
    };

    return images.length > 0 ? (
        <Box
            sx={{
                width: "100%",
            }}
        >
            <Typography width="100%" textAlign="center">
                {currentIndex + 1} / {images.length}
            </Typography>
            <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent={"space-between"}
                gap={1}
            >
                <IconButton onClick={handlePrev}>
                    <ArrowBack />
                </IconButton>
                {renderItem(images[currentIndex] ?? { path: "no_image" })}
                <IconButton onClick={handleNext}>
                    <ArrowForward />
                </IconButton>
            </Stack>
        </Box>
    ) : (
        <Typography variant="subtitle2" color="gray">
            {MVR_LABELS.SELECT_IMAGES[LANG]}
        </Typography>
    );
};

ImageCarousel.propTypes = {
    images: PropTypes.array.isRequired,
    renderItem: PropTypes.func.isRequired,
};
