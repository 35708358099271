import { COLORS } from "../constants/colors";

export const toRgb = (color) => {
    if (COLORS[color]) {
        color = COLORS[color];
    }
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    return result
        ? [
              parseInt(result[1], 16),
              parseInt(result[2], 16),
              parseInt(result[3], 16),
          ]
        : null;
};
