import { Grid, Skeleton, Stack } from "@mui/material";

export const ValidationReportSkeleton = () => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={7}>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height="10em"
                />
            </Grid>
            <Grid item xs={5}>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height="10em"
                />
            </Grid>
            <Grid item xs={2.5}>
                <Stack gap={1}>
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height="7em"
                    />
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height="7em"
                    />
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height="7em"
                    />
                </Stack>
            </Grid>
            <Grid item xs={9.5}>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height="22em"
                />
            </Grid>
        </Grid>
    );
};
