import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import {
    ENTER_KEY,
    ENTITY_LISTS,
    ENTITY_SELECT_OPTION_FONT_FAMILY,
    ENTITY_SELECT_SIZE,
} from "../../../constants";

import { ArrowForward } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { formatDateTimeShort } from "../../../utils/formatTime";
import { regroupByPhenostation } from "../utils/dataStructure";
import { useState } from "react";
import { useTheme } from "@emotion/react";

/* 
Unfortunately this way of defining StyledOption causes a warning from React
about the "unrecognized" prop isGrayedOut.
const SytledOption = styled("option")(({ theme, isGrayedOut }) => ({
    color: isGrayedOut
        ? theme.palette.text.disabled
        : theme.palette.text.primary,
}));
*/

const SytledOption = ({ id, value, label, isGrayedOut }) => {
    const theme = useTheme();
    const color = isGrayedOut
        ? theme.palette.text.disabled
        : theme.palette.text.primary;
    return (
        <option
            key={id}
            value={value}
            style={{ color, fontFamily: ENTITY_SELECT_OPTION_FONT_FAMILY }}
        >
            {label}
        </option>
    );
};

function EntitySelect({
    title,
    entities,
    markedEntities,
    setMarkedEntities,
    onFocus,
    entitiesToGreyOut = [],
    onDoubleClickOrEnter,
}) {
    const handleChangeMultiple = (event) => {
        const { options } = event.target;

        const newMarkedEntities = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                newMarkedEntities.push(
                    entities.find((entity) => entity.uuid === options[i].value)
                );
            }
        }

        setMarkedEntities(newMarkedEntities);
    };

    const phenostationList = regroupByPhenostation(entities, true);
    const entityUuidsToGreyOut = entitiesToGreyOut.map((entity) => entity.uuid);

    const [textFilter, setTextFilter] = useState("");

    return (
        <FormControl sx={{ width: 1 }}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle1">{title}</Typography>
                <Typography
                    variant="subtitle2"
                    color="text.secondary"
                >{`Count: ${entities.length}`}</Typography>
            </Stack>

            <Stack spacing={1}>
                <TextField
                    variant="outlined"
                    placeholder="search ref"
                    size="small"
                    value={textFilter}
                    onChange={(event) => setTextFilter(event.target.value)}
                ></TextField>
                <Select
                    multiple
                    native
                    value={markedEntities.map((entity) => entity.uuid)}
                    onChange={handleChangeMultiple}
                    // Press Enter or double click on list items
                    onDoubleClick={onDoubleClickOrEnter}
                    onKeyDown={(event) => {
                        if (event.key === ENTER_KEY) {
                            onDoubleClickOrEnter();
                        }
                    }}
                    inputProps={{ size: ENTITY_SELECT_SIZE }}
                    onFocus={onFocus}
                >
                    {phenostationList.map((phenostation) => (
                        <optgroup
                            key={phenostation.uuid}
                            label={phenostation.name}
                        >
                            {phenostation.entities
                                .filter((entity) =>
                                    entity.entityRef
                                        .toLowerCase()
                                        .includes(textFilter.toLowerCase())
                                )
                                .map((entity) => (
                                    <SytledOption
                                        key={entity.uuid}
                                        value={entity.uuid}
                                        isGrayedOut={entityUuidsToGreyOut.includes(
                                            entity.uuid
                                        )}
                                        label={`${formatDateTimeShort(
                                            entity.Acquisition
                                                .acquisitionDatetime
                                        )} ${entity.entityRef}`}
                                    />
                                ))}
                        </optgroup>
                    ))}
                </Select>
            </Stack>
        </FormControl>
    );
}

export const TransferList = ({
    evaluationEntities,
    selectedEntities,
    setSelectedEntities,
    markedEvaluationEntities,
    setMarkedEvaluationEntities,
    markedSelectedEntities,
    setMarkedSelectedEntities,
    setLastFocusedEntityList,
}) => {
    const handleTransfert = () => {
        const selectedEntityUuids = selectedEntities.map(
            (entity) => entity.uuid
        );
        const newSelectedEntities = markedEvaluationEntities.filter(
            (entity) => !selectedEntityUuids.includes(entity.uuid)
        );
        setSelectedEntities(selectedEntities.concat(newSelectedEntities));
    };

    const handlePop = () => {
        const markedSelectedEntityUuids = markedSelectedEntities.map(
            (entity) => entity.uuid
        );
        setSelectedEntities(
            selectedEntities.filter((entity) => {
                return !markedSelectedEntityUuids.includes(entity.uuid);
            })
        );
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={5.5}>
                <EntitySelect
                    title="Evaluation Entities"
                    entities={evaluationEntities}
                    markedEntities={markedEvaluationEntities}
                    setMarkedEntities={setMarkedEvaluationEntities}
                    entitiesToGreyOut={selectedEntities}
                    onDoubleClickOrEnter={handleTransfert}
                    onFocus={() =>
                        setLastFocusedEntityList(
                            ENTITY_LISTS.EVALUATION_ENTITY_LIST
                        )
                    }
                />
            </Grid>
            <Grid item xs={1}>
                <Stack
                    spacing={1}
                    direction="column"
                    justifyContent="center"
                    sx={{ height: "100%" }}
                >
                    <Button
                        sx={{ width: 1, minWidth: 1 }}
                        disabled={!markedEvaluationEntities.length}
                        variant="contained"
                        onClick={handleTransfert}
                        size="small"
                    >
                        <ArrowForward fontSize="small" />
                    </Button>
                    <Button
                        sx={{ width: 1, minWidth: 1 }}
                        disabled={!markedSelectedEntities.length}
                        variant="contained"
                        color="error"
                        onClick={handlePop}
                        size="small"
                    >
                        <ClearIcon fontSize="small" />
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={5.5}>
                <EntitySelect
                    title="Selected Entities"
                    entities={selectedEntities}
                    markedEntities={markedSelectedEntities}
                    setMarkedEntities={setMarkedSelectedEntities}
                    onDoubleClickOrEnter={handlePop}
                    onFocus={() =>
                        setLastFocusedEntityList(
                            ENTITY_LISTS.SELECTED_ENTITY_LIST
                        )
                    }
                />
            </Grid>
        </Grid>
    );
};
