import {
    ACQUISITION_DATETIME_TRAIT_NAME,
    DEFAULT_BIN_COUNT,
    DEFAULT_DATE_RANGE_DAYS,
    GROUP_MODES,
    PAGINATION,
    PLOT_TABS,
    PROCESSING_PROJECT_VERSION,
} from "../../constants";
import { Grid, Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { DataSourceSection } from "./components/DataSourceSection";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { FilterSection } from "./components/FilterSection";
import { GraphControlsSection } from "./components/GraphControlsSection";
import { PlotSection } from "./components/PlotSection";
import { SelectionSection } from "./components/SelectionSection";
import { VisualFilterSection } from "./components/VisualFilterSection";
import { VisualisationSection } from "./components/VisualisationSection";
import { isValid } from "date-fns";
import useSWR from "swr";
import { useSWRAllPages } from "../../hooks/useSWRAllPages";

const getEntitiesUrl = (
    project,
    selectedPhenostations,
    startDatetime,
    endDatetime
) => {
    if (
        selectedPhenostations.length &&
        isValid(startDatetime) &&
        isValid(endDatetime)
    ) {
        let url = `${BACKEND_ROUTES.PROJECT}/${project.uuid}/${BACKEND_ROUTES.PROJECT_ENTITIES}?`;

        selectedPhenostations.forEach((phenostation) => {
            url += `phenostationUuid[]=${phenostation.uuid}&`;
        });

        url += `startDatetime=${startDatetime.toISOString()}&`;
        url += `endDatetime=${endDatetime.toISOString()}`;

        return url;
    } else {
        return null;
    }
};

export function MonitoringDashboard({ project }) {
    // The "saved" counterparts represent the "submitted" data
    // We can replace these 3 fields by react hook form in the future for easier management
    const [selectedPhenostations, setSelectedPhenostations] = useState([]);
    const [savedSelectedPhenostations, setSavedSelectedPhenostations] =
        useState([]);

    const [startDatetime, setStartDatetime] = useState(
        new Date(Date.now() - DEFAULT_DATE_RANGE_DAYS * 24 * 60 * 60 * 1000) // 3 days ago
    );
    const [savedStartDatetime, setSavedStartDatetime] = useState(null);

    const [endDatetime, setEndDatetime] = useState(new Date());
    const [savedEndDatetime, setSavedEndDatetime] = useState(null);

    // Get list of all phenostations of the current project
    const { data: phenostationsData, error: phenostationsFetchError } =
        useSWRAllPages(
            `${BACKEND_ROUTES.PROJECT}/${project.uuid}/phenostations`,
            PAGINATION.GENERIC.LIMIT.MAX
        );

    /**
     * Get the number of entities that will be fetched if user clicks on the "Get Entities" button.
     * It is obtained from the count of the first page of the same url of the "Get Entities" button
     * with the "selected" list of phenostations, start and end datetimes.
     */
    const entitiesCountUrl = getEntitiesUrl(
        project,
        selectedPhenostations,
        startDatetime,
        endDatetime
    );
    const { data: entitiesFirstPage, error: entitiesFirstPageFetchError } =
        useSWR(entitiesCountUrl ? `${entitiesCountUrl}&limit=1` : null, {
            revalidateOnFocus: false,
        });

    // Get all entities of the "saved" list of phenostations, start and end datetimes
    const {
        data: entitiesData,
        error: entitiesFetchError,
        isValidating: entitiesFetchIsValidating,
        mutate: entitiesMutate,
    } = useSWRAllPages(
        getEntitiesUrl(
            project,
            savedSelectedPhenostations,
            savedStartDatetime,
            savedEndDatetime
        ),
        PAGINATION.GENERIC.LIMIT.MAX,
        { revalidateOnFocus: false }
    );

    /**
     * The "save" function is called by the "Get Entities" button.
     * It calls the entities fetch mutate manually if the 3 fields have not changed (i.e. the url has not changed)
     * so that it still triggers a refetch.
     */
    const save = () => {
        if (
            getEntitiesUrl(
                project,
                savedSelectedPhenostations,
                savedStartDatetime,
                savedEndDatetime
            ) ===
            getEntitiesUrl(
                project,
                selectedPhenostations,
                startDatetime,
                endDatetime
            )
        )
            entitiesMutate();

        setSavedSelectedPhenostations(selectedPhenostations);
        setSavedStartDatetime(startDatetime);
        setSavedEndDatetime(endDatetime);
    };

    // We need to use useMemo on unfilteredEntities for the useMemo of processingVersionList to work
    const phenostations = useMemo(
        () => phenostationsData ?? [],
        [phenostationsData]
    );
    const unfilteredEntities = useMemo(
        () => entitiesData ?? [],
        [entitiesData]
    );

    // Get the list of unique processing versions (sorted)
    const processingVersionList = useMemo(
        () => getProcessingVersionList(unfilteredEntities),
        [unfilteredEntities]
    );

    const tagList = useMemo(
        () => getTagList(unfilteredEntities),
        [unfilteredEntities]
    );

    const [xTrait, setXTrait] = useState(null);
    const [yTrait, setYTrait] = useState(null);
    const [groupMode, setGroupMode] = useState(GROUP_MODES.PHENOSTATION);
    const [selectedProcessingVersions, setSelectedProcessingVersions] =
        useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [evaluationEntities, setEvaluationEntities] = useState([]);

    const [binCount, setBinCount] = useState(DEFAULT_BIN_COUNT);

    // Visual
    const [markerOpacity, setMarkerOpacity] = useState(1.0);
    const [lineWidth, setLineWidth] = useState(1.0);
    const [showLegend, setShowLegend] = useState(true);
    const [activeTab, setActiveTab] = useState(PLOT_TABS.HISTOGRAM);

    // uirevision is used to persist user changes on plotly plots (for example: selection)
    // However, clean user changes when appropriate (when the plot changes for example).
    const [uirevision, setUirevision] = useState(Math.random());
    useEffect(
        () => setUirevision(Math.random()),
        [
            savedSelectedPhenostations,
            savedStartDatetime,
            savedEndDatetime,
            xTrait,
            yTrait,
            groupMode,
            selectedProcessingVersions,
            selectedTags,
            binCount,
        ]
    );

    // Filter entities and convert datetime string to Date objects
    const entities = useMemo(
        () =>
            // if no selected processing versions, do not filter
            unfilteredEntities
                .filter((entity) => {
                    if (
                        selectedProcessingVersions.length &&
                        !selectedProcessingVersions.includes(
                            entity.processingProjectVersion
                        )
                    )
                        return false;
                    if (
                        selectedTags.length &&
                        !entity.tags.some((tag) => selectedTags.includes(tag))
                    )
                        return false;

                    return true;
                })
                .map((entity) => ({
                    ...entity,
                    Acquisition: {
                        ...entity.Acquisition,
                        acquisitionDatetime: new Date(
                            entity.Acquisition.acquisitionDatetime
                        ),
                    },
                })),
        [unfilteredEntities, selectedProcessingVersions, selectedTags]
    );

    const mergedFetchError =
        phenostationsFetchError ??
        entitiesFetchError ??
        entitiesFirstPageFetchError;
    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    const entitiesCount = entitiesFirstPage?.count ?? 0;

    // Get list of traits
    const xTraitList = entities[0]?.traits
        ? Object.keys(entities[0].traits)
        : [];
    // Make a copy of xTraitList for yTraitList
    const yTraitList = xTraitList.slice();

    if (xTraitList.length) {
        // Add acquisition datetime to xTraitList if it is not empty
        xTraitList.unshift(ACQUISITION_DATETIME_TRAIT_NAME);
        // Add processing_project_version to xTraitList if it is not empty
        xTraitList.push(PROCESSING_PROJECT_VERSION);
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h5">{project.name}</Typography>
            </Grid>
            <Grid item xs={2}>
                <Stack spacing={1}>
                    <DataSourceSection
                        project={project}
                        phenostations={phenostations}
                        selectedPhenostations={selectedPhenostations}
                        setSelectedPhenostations={setSelectedPhenostations}
                        startDatetime={startDatetime}
                        setStartDatetime={setStartDatetime}
                        endDatetime={endDatetime}
                        setEndDatetime={setEndDatetime}
                        entitiesCount={entitiesCount}
                        save={save}
                        entitiesFetchIsValidating={entitiesFetchIsValidating}
                    />
                    <FilterSection
                        processingVersionList={processingVersionList}
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                        tagList={tagList}
                        selectedProcessingVersions={selectedProcessingVersions}
                        setSelectedProcessingVersions={
                            setSelectedProcessingVersions
                        }
                    />
                    <GraphControlsSection
                        xTraitList={xTraitList}
                        yTraitList={yTraitList}
                        xTrait={xTrait}
                        setXTrait={setXTrait}
                        yTrait={yTrait}
                        setYTrait={setYTrait}
                        groupMode={groupMode}
                        setGroupMode={setGroupMode}
                        binCount={binCount}
                        setBinCount={setBinCount}
                        activeTab={activeTab}
                    />
                    <VisualFilterSection
                        activeTab={activeTab}
                        markerOpacity={markerOpacity}
                        setMarkerOpacity={setMarkerOpacity}
                        lineWidth={lineWidth}
                        setLineWidth={setLineWidth}
                        showLegend={showLegend}
                        setShowLegend={setShowLegend}
                    />
                </Stack>
            </Grid>
            <Grid item xs={5}>
                <Stack spacing={1}>
                    <PlotSection
                        entities={entities}
                        groupMode={groupMode}
                        xTrait={xTrait}
                        yTrait={yTrait}
                        setEvaluationEntities={setEvaluationEntities}
                        binCount={binCount}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        markerOpacity={markerOpacity}
                        lineWidth={lineWidth}
                        showLegend={showLegend}
                        uirevision={uirevision}
                    />

                    <SelectionSection
                        entities={entities}
                        setEvaluationEntities={setEvaluationEntities}
                        xTrait={xTrait}
                        activeTab={activeTab}
                    />
                </Stack>
            </Grid>
            <Grid item xs={5}>
                <VisualisationSection
                    evaluationEntities={evaluationEntities}
                    setEvaluationEntities={setEvaluationEntities}
                    xTrait={xTrait}
                    yTrait={yTrait}
                />
            </Grid>
        </Grid>
    );
}

/**
 * Get the list of unique processing versions (sorted) from a list of entities.
 */
function getProcessingVersionList(entities) {
    const processingVersionList = [];

    entities.forEach((entity) => {
        const processingVersion = entity.processingProjectVersion;
        if (!processingVersionList.includes(processingVersion))
            processingVersionList.push(processingVersion);
    });

    processingVersionList.sort();

    return processingVersionList;
}

function getTagList(entities) {
    const tags = entities.flatMap((entity) => entity.tags);
    return [...new Set(tags)];
}
