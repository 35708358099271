export const cardStyleOverride = {
    MuiCard: {
        styleOverrides: {
            root: ({ theme }) => ({
                boxShadow: theme.customShadows.z1,
                borderRadius: Number(theme.shape.borderRadius) * 2,
                position: "relative",
                zIndex: 0, // Fix Safari overflow: hidden with border radius
            }),
        },
    },
    MuiCardHeader: {
        defaultProps: {
            titleTypographyProps: { variant: "h6" },
            subheaderTypographyProps: { variant: "body2" },
        },
        styleOverrides: {
            root: ({ theme }) => ({
                padding: theme.spacing(3, 3, 0),
            }),
        },
    },
    MuiCardContent: {
        styleOverrides: {
            root: ({ theme }) => ({
                padding: theme.spacing(3),
            }),
        },
    },
};
