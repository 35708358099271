import { Box, Grid, Skeleton, Stack, Switch, Typography } from "@mui/material";

import { DataDisplay } from "../../components/DataDisplay/DataDisplay";
import { ImageCarousel } from "../../components/ImageCarousel/ImageCarousel";
import { ModelVersionFile } from "./ModelVersionFile";
import { PerformanceScatter } from "./PerformanceScatter";
import { Section } from "../../../../components/Section";
import { useState } from "react";

export const SemanticSegmentationTemplate = ({
    versionData,
    dataset,
    comparedVersionData,
    classes,
    modelIdCard,
    versionTimeline,
    selectedModelVersion,
    versionDataIsValidating,
}) => {
    const [surfaceMode, setSurfaceMode] = useState(true);
    const [selectedImages, setSelectedImages] = useState([]);

    return (
        <Grid container>
            <Grid item container xs={7} sx={{ p: 0.5 }}>
                <Grid item xs={12}>
                    {modelIdCard}
                </Grid>
                <Grid item xs={4}>
                    {versionTimeline}
                </Grid>
                <Grid item xs={8} sx={{ pt: 1 }}>
                    {selectedModelVersion &&
                        (versionDataIsValidating ? (
                            <Skeleton sx={{ height: "50vh" }} />
                        ) : (
                            <Section>
                                <Box sx={{ height: "50vh" }}>
                                    {versionData && (
                                        <PerformanceScatter
                                            data={versionData.ModelVersionFiles}
                                            dataset={dataset}
                                            comparedVersionData={
                                                comparedVersionData
                                            }
                                            classes={classes}
                                            surfaceMode={surfaceMode}
                                            handleImageSelection={
                                                setSelectedImages
                                            }
                                            selectedModelVersion={
                                                selectedModelVersion
                                            }
                                        />
                                    )}
                                </Box>
                                <Stack
                                    direction="row"
                                    sx={{
                                        alignItems: "center",
                                        float: "right",
                                        p: 1,
                                    }}
                                >
                                    <Typography>Nombre</Typography>
                                    <Switch
                                        onChange={() =>
                                            setSurfaceMode(!surfaceMode)
                                        }
                                        color="default"
                                        checked={surfaceMode}
                                    />
                                    <Typography>Surface (%)</Typography>
                                </Stack>
                            </Section>
                        ))}
                </Grid>
            </Grid>
            <Grid item xs={5} sx={{ p: 0.5 }}>
                <DataDisplay
                    components={[
                        {
                            name: "Explorateur d'images",
                            jsx: (
                                <ImageCarousel
                                    key={[versionData, selectedImages]}
                                    images={selectedImages}
                                    renderItem={(imageName) => (
                                        <ModelVersionFile
                                            classes={classes}
                                            currentVersion={versionData?.name}
                                            versionDataIsValidating={
                                                versionDataIsValidating
                                            }
                                            modelVersionFile={
                                                versionData
                                                    ? versionData.ModelVersionFiles.find(
                                                          (file) =>
                                                              file.imageName ===
                                                              imageName
                                                      )
                                                    : null
                                            }
                                            file={dataset.find(
                                                ({ name }) => name === imageName
                                            )}
                                            comparedModelVersionFile={
                                                comparedVersionData
                                                    ? comparedVersionData.ModelVersionFiles.find(
                                                          (image) =>
                                                              image.imageName ===
                                                              imageName
                                                      )
                                                    : null
                                            }
                                        />
                                    )}
                                />
                            ),
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
};
