import { Box, Tab, Tabs } from "@mui/material";

import PropTypes from "prop-types";
import { Section } from "../../../../components/Section";
import { useState } from "react";

function TabPanel({ children, value, index }) {
    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            sx={{ maxHeight: "85vh", overflow: "scroll" }}
        >
            {value === index && (
                <Box
                    sx={{
                        p: 2,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {children}
                </Box>
            )}
        </Box>
    );
}
// components: array {name and jsx}
export const DataDisplay = ({ components }) => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box
            sx={{
                borderColor: "divider",
                maxHeight: "50vh",
            }}
        >
            <Section cardStyles={{ p: 0 }}>
                <Tabs value={value} onChange={handleChange}>
                    {components.map((component, index) => (
                        <Tab key={index} label={component.name} value={index} />
                    ))}
                </Tabs>
                {components.map((component, index) => (
                    <TabPanel key={index} value={value} index={index}>
                        {component.jsx}
                    </TabPanel>
                ))}
            </Section>
        </Box>
    );
};

DataDisplay.propTypes = {
    components: PropTypes.array.isRequired,
};
