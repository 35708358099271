import {
    CHART_MODEBAR_BUTTONS_TO_REMOVE,
    GROUP_MODES,
    HISTOGRAM_CHART_MARGINS,
    HISTOGRAM_DIV_ID,
    MODEL_PROPTYPES,
} from "../../../constants";
import { Stack, Typography } from "@mui/material";
import {
    getEntityTrait,
    regroupByPhenostation,
    regroupByProcessingVersion,
    regroupByStationEntityRef,
} from "../utils/dataStructure";

import Plot from "react-plotly.js";
import PropTypes from "prop-types";
import { useState } from "react";

Histogram.propTypes = {
    entities: PropTypes.arrayOf(
        PropTypes.shape({
            ...MODEL_PROPTYPES.EntityWithParents,
        })
    ).isRequired,
    groupMode: PropTypes.oneOf(Object.values(GROUP_MODES)).isRequired,
    traitName: PropTypes.string.isRequired,
    binCount: PropTypes.number.isRequired,
    onSelected: PropTypes.func,
    uirevision: PropTypes.any,
    showLegend: PropTypes.bool,
};

/**
 * onSelected: Callback fired when some points are selected or there is an "unselection".
 * Signature: function(selectedEntities: Array|undefined) => void
 * selectedEntities: An array of selected entities or undefined if there is an "unselection".
 */
export function Histogram({
    entities,
    groupMode,
    traitName,
    binCount,
    onSelected,
    uirevision,
    showLegend,
}) {
    const [calculatedBinCount, setCalculatedBinCount] = useState(undefined);

    let groupList;

    groupList = entities.filter(
        (entity) => getEntityTrait(entity, traitName) != null
    );

    switch (groupMode) {
        case GROUP_MODES.PHENOSTATION:
            groupList = regroupByPhenostation(groupList);
            break;
        case GROUP_MODES.ENTITY:
            groupList = regroupByStationEntityRef(groupList);
            break;
        case GROUP_MODES.PROCESSING_VERSION:
            groupList = regroupByProcessingVersion(groupList);
            break;
        default:
            groupList = regroupByPhenostation(groupList);
    }

    const updateCalculatedBinCount = () => {
        const calculatedBinCount =
            document.getElementById(HISTOGRAM_DIV_ID)?.calcdata?.[0]?.length;
        setCalculatedBinCount(calculatedBinCount);
    };

    return (
        <Stack direction="column" height={0.85}>
            <Plot
                config={{
                    displaylogo: false,
                    modeBarButtonsToRemove: CHART_MODEBAR_BUTTONS_TO_REMOVE,
                }}
                data={groupList.map((group) => {
                    return {
                        x: group.entities.map((entity) =>
                            getEntityTrait(entity, traitName)
                        ),
                        customdata: group.entities,
                        nbinsx: binCount,
                        type: "histogram",
                        name: group.name,
                    };
                })}
                layout={{
                    xaxis: { title: traitName },
                    yaxis: { title: "Count" },
                    margin: HISTOGRAM_CHART_MARGINS,
                    barmode: "stack",
                    dragmode: "select",
                    autosize: true,
                    uirevision: uirevision,
                    showlegend: showLegend,
                }}
                onSelected={(eventData) => {
                    if (onSelected) {
                        // eventData is undefined in the case of an "unselection" (a double-click for example)
                        if (eventData !== undefined) {
                            const selectedEntities = [];

                            eventData.points.forEach((bin) => {
                                bin.pointNumbers.forEach((index) => {
                                    selectedEntities.push(
                                        groupList[bin.curveNumber].entities[
                                            index
                                        ]
                                    );
                                });
                            });

                            onSelected(selectedEntities);
                        } else onSelected(undefined);
                    }
                }}
                useResizeHandler
                style={{ width: "100%", height: "100%" }}
                divId={HISTOGRAM_DIV_ID}
                onAfterPlot={updateCalculatedBinCount}
            />
            {calculatedBinCount && (
                <Typography sx={{ color: "text.secondary" }}>
                    Actual bin count: {calculatedBinCount}
                </Typography>
            )}
        </Stack>
    );
}
