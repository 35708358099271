import { Box, Button, Divider, Grid, Slider } from "@mui/material";
import {
    ContentCut,
    DeleteOutline,
    Download,
    ImageSearch,
} from "@mui/icons-material";
import { ENTITY_LISTS, MODEL_PROPTYPES } from "../../../constants";
import React, { useEffect, useState } from "react";

import { EntityVisualizer } from "./EntityVisualizer";
import PropTypes from "prop-types";
import { Section } from "../../../components/Section";
import { TransferList } from "./TransferList";
import { randomSample } from "../utils/sampling";

VisualisationSection.propTypes = {
    evaluationEntities: PropTypes.arrayOf(
        PropTypes.shape({
            ...MODEL_PROPTYPES.EntityWithParents,
        })
    ).isRequired,
    xTrait: PropTypes.string,
    yTrait: PropTypes.string,
};

export function VisualisationSection({
    evaluationEntities,
    setEvaluationEntities,
    xTrait,
    yTrait,
}) {
    const [sampleCount, setSampleCount] = useState(1);
    const [selectedEntities, setSelectedEntities] = useState([]);

    const [markedEvaluationEntities, setMarkedEvaluationEntities] = useState(
        []
    );
    const [markedSelectedEntities, setMarkedSelectedEntities] = useState([]);

    // In order for the entity visualizer to work for both evaluation entity list and selected entity list,
    // we use the last focused list of the two
    const [lastFocusedEntityList, setLastFocusedEntityList] = useState(null);

    // In order to prevent the situation that marked entities are not in current list
    useEffect(() => {
        const evaluationEntityUuids = evaluationEntities.map(
            (entity) => entity.uuid
        );
        setMarkedEvaluationEntities((previousList) =>
            previousList.filter((entity) =>
                evaluationEntityUuids.includes(entity.uuid)
            )
        );

        const selectedEntityUuids = selectedEntities.map(
            (entity) => entity.uuid
        );
        setMarkedSelectedEntities((previousList) =>
            previousList.filter((entity) =>
                selectedEntityUuids.includes(entity.uuid)
            )
        );
    }, [evaluationEntities, selectedEntities]);

    const exportSelectedEntities = () => {
        const extracted = selectedEntities.map((entity) => ({
            entityUuid: entity.uuid,
            acquisitionUuid: entity.acquisitionUuid,
        }));

        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(extracted)
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = `exported-entities-${Date.now().toString()}.json`;

        link.click();
    };

    const clearSelection = () => {
        setEvaluationEntities([]);
    };

    const visualizedEntity =
        lastFocusedEntityList === ENTITY_LISTS.EVALUATION_ENTITY_LIST
            ? markedEvaluationEntities[0]
            : markedSelectedEntities[0];

    return (
        <Section icon={<ImageSearch />} title="Trait Visualisation">
            <TransferList
                evaluationEntities={evaluationEntities}
                selectedEntities={selectedEntities}
                setSelectedEntities={setSelectedEntities}
                markedEvaluationEntities={markedEvaluationEntities}
                setMarkedEvaluationEntities={setMarkedEvaluationEntities}
                markedSelectedEntities={markedSelectedEntities}
                setMarkedSelectedEntities={setMarkedSelectedEntities}
                setLastFocusedEntityList={setLastFocusedEntityList}
            />

            <Grid
                container
                sx={{
                    py: 1,
                }}
                spacing={1}
                justifyContent="space-between"
            >
                <Grid item xs={5.5}>
                    <Grid container spacing={0.5}>
                        <Grid item xs={12}>
                            <Box sx={{ px: 2 }}>
                                <Slider
                                    value={sampleCount}
                                    disabled={evaluationEntities.length === 0}
                                    onChange={(_, value) =>
                                        setSampleCount(value)
                                    }
                                    min={1}
                                    max={Math.max(evaluationEntities.length, 1)}
                                    marks
                                    color="secondary"
                                ></Slider>
                            </Box>
                        </Grid>
                        <Grid item sx={{ flexGrow: 1 }}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    const sampledEntities = randomSample(
                                        evaluationEntities,
                                        sampleCount
                                    );
                                    setEvaluationEntities(sampledEntities);
                                }}
                                disabled={evaluationEntities.length === 0}
                                startIcon={<ContentCut />}
                                size="small"
                                color="secondary"
                                sx={{ textTransform: "none" }}
                            >
                                Reduce to {sampleCount} random sample(s)
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                color="error"
                                variant="outlined"
                                onClick={clearSelection}
                                disabled={evaluationEntities.length === 0}
                                startIcon={<DeleteOutline />}
                                size="small"
                            >
                                Empty
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Button
                        disabled={!selectedEntities.length}
                        variant="outlined"
                        onClick={exportSelectedEntities}
                        startIcon={<Download />}
                        size="small"
                    >
                        Export selected entities
                    </Button>
                </Grid>
            </Grid>

            <Divider sx={{ mb: 1 }}></Divider>

            {/* In order for the label selection state to persist, we put the undefined entity check inside the component */}
            <EntityVisualizer
                entity={visualizedEntity}
                xTrait={xTrait}
                yTrait={yTrait}
            />
        </Section>
    );
}
