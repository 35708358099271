import { Box, FormLabel, Grid, Slider, Stack, Switch } from "@mui/material";

import { PLOT_TABS } from "../../../constants";
import { Section } from "../../../components/Section";
import { Tune } from "@mui/icons-material";

export const VisualFilterSection = ({
    activeTab,
    markerOpacity,
    setMarkerOpacity,
    lineWidth,
    setLineWidth,
    showLegend,
    setShowLegend,
}) => {
    return (
        <Section icon={<Tune />} title="Visual Modifiers">
            <Grid container alignItems="center">
                <Grid item xs={6}>
                    <FormLabel>Marker Opacty</FormLabel>
                </Grid>

                <Grid item xs={6}>
                    <Stack spacing={2} direction="row" alignItems="center">
                        <Box>0</Box>
                        <Slider
                            size="small"
                            disabled={activeTab === PLOT_TABS.HISTOGRAM}
                            value={markerOpacity}
                            step={0.01}
                            min={0.0}
                            max={1.0}
                            onChange={(_, value) => {
                                setMarkerOpacity(value);
                            }}
                        />
                        <Box>1</Box>
                    </Stack>
                </Grid>

                <Grid item xs={6}>
                    <FormLabel>Line Width</FormLabel>
                </Grid>

                <Grid item xs={6}>
                    <Stack spacing={2} direction="row" alignItems="center">
                        <Box>0</Box>
                        <Slider
                            size="small"
                            disabled={activeTab === PLOT_TABS.HISTOGRAM}
                            value={lineWidth}
                            step={0.01}
                            min={0.0}
                            max={1.0}
                            onChange={(_, value) => {
                                setLineWidth(value);
                            }}
                        />
                        <Box>1</Box>
                    </Stack>
                </Grid>

                <Grid item xs={6}>
                    <FormLabel>Show Legend</FormLabel>
                </Grid>

                <Grid item xs={6}>
                    <Switch
                        size="small"
                        label="Show Legend"
                        defaultChecked
                        value={showLegend}
                        onChange={(_, v) => setShowLegend(v)}
                    ></Switch>
                </Grid>
            </Grid>
        </Section>
    );
};
