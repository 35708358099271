import { addSearchParams } from "../utils/urlUtils";
import useSWRInfinite from "swr/infinite";

/**
 * A custom hook that basically is useSWRInfinite but automatically loads all pages.
 * @param {(string|function|null)} getKey - The url to fetch. The conditional fetching of SWR is
 * preserved through the fact that a function (which can throw or return a falsy value) or null is accepted,
 * in addition to a string.
 * @param {number} pageLimit - The number of elements to request per page. Should be an integer.
 * @param {*} options - Options that are passed directly to useSWRInfinite.
 * @returns {{ data, error, isValidating, mutate}} Same objects directly from useSWRInfinite, except that data has already be flat-mapped.
 */
export function useSWRAllPages(getKey, pageLimit, options = undefined) {
    const { data, error, size, setSize, isValidating, mutate } = useSWRInfinite(
        getKeyWithPagination(getKey, pageLimit),
        options
    );

    if (data) {
        // Total number of rows in the database
        const dbRowCount = data[0]?.count;

        if (dbRowCount) {
            // Total number of pages with the page limit provided
            const pageCount = Math.ceil(dbRowCount / pageLimit);

            // Fetch all pages if not already the case
            if (size !== pageCount) setSize(pageCount);
        }
    }

    return {
        data: data ? data.flatMap((onePageData) => onePageData.rows) : data,
        error,
        isValidating,
        mutate,
    };
}

/**
 * Transform most SWR getKey into one that can be used with SWRInfinite.
 * @param {(string|function|null)} getKey - The url to fetch. The conditional fetching of SWR is
 * preserved through the fact that a function (which can throw or return a falsy value) or null is accepted,
 * in addition to a string.
 * @param {number} pageLimit - The number of elements to request per page. Should be an integer.
 * @returns {function}
 */
function getKeyWithPagination(getKey, pageLimit) {
    let url;

    if (typeof getKey === "function") {
        try {
            url = getKey();
        } catch {
            url = undefined;
        }
    } else {
        url = getKey;
    }

    if (!url) return () => null;

    if (!(typeof url === "string" || url instanceof String))
        throw new TypeError("getKey does not return a string");

    if (!Number.isInteger(pageLimit))
        throw new TypeError("pageLimit must be an integer");

    return (pageIndex, previousPageData) => {
        // Reached the end
        if (previousPageData && previousPageData.count < pageIndex * pageLimit)
            return null;

        // Add offset and limit query parameters
        let urlObject = new URL(url, window.location.origin);
        urlObject = addSearchParams(urlObject, {
            offset: (pageIndex * pageLimit).toString(),
            limit: pageLimit.toString(),
        });

        return urlObject.href;
    };
}
