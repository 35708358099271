/* eslint-disable camelcase */
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CustomSWRConfig } from "./context/swr/CustomSWRConfig";
import { FetchProvider } from "./context/fetch/FetchProvider";
import GlobalStyles from "./theme/globalStyles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { OidcProvider } from "@axa-fr/react-oidc";
import Router from "./routes";
import { SnackbarProvider } from "./context/snackbar/SnackbarProvider";
import { ThemeConfig } from "./theme";
import useScrollToTop from "./components/ScrollToTop";

const configuration = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    scope: process.env.REACT_APP_SCOPE,
    authority: process.env.REACT_APP_AUTHORITY,
    service_worker_relative_url: "/OidcServiceWorker.js",
    service_worker_only: true,
};

export default function App() {
    useScrollToTop();

    return (
        <ThemeConfig>
            <GlobalStyles />
            <SnackbarProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CustomSWRConfig>
                        <FetchProvider>
                            <OidcProvider configuration={configuration}>
                                <Router />
                            </OidcProvider>
                        </FetchProvider>
                    </CustomSWRConfig>
                </LocalizationProvider>
            </SnackbarProvider>
        </ThemeConfig>
    );
}
