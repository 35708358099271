import { BACKEND_ROUTES } from "../../../../backendRoutes";
import { Box } from "@mui/material";
import { FetchErrorAlert } from "../../../../components/FetchErrorAlert";
import { ImageCarousel } from "../ImageCarousel/ImageCarousel";
import PropTypes from "prop-types";
import useSWR from "swr";

const FetchedImage = ({ path }) => {
    const {
        data: src,
        isValidating: imageLoading,
        error: imageFetchError,
    } = useSWR(`${BACKEND_ROUTES.DEEP_LEARNING_MODELS}/images?uri=${path}`);

    if (imageFetchError) return <FetchErrorAlert error={imageFetchError} />;
    return imageLoading ? <>Loading</> : <img alt={path} src={src} />;
};

export const ImageExplorer = ({ images }) => {
    return (
        <Box
            sx={{
                width: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}
        >
            <ImageCarousel
                images={images}
                renderItem={({ path }) => (
                    <Box
                        sx={{
                            overflow: "hidden",
                            height: "70vh",
                        }}
                    >
                        <FetchedImage path={path} />
                    </Box>
                )}
            />
        </Box>
    );
};

ImageExplorer.propTypes = {
    images: PropTypes.array.isRequired,
};
