import { useEffect } from "react";
import { useOidc } from "@axa-fr/react-oidc";

export default function Login() {
    const { login } = useOidc();
    useEffect(() => {
        login("/");
    });
    return <>You should be redirected to login page soon</>;
}
