import { useEffect, useRef } from "react";

import { Box } from "@mui/material";
import PropTypes from "prop-types";
import useSWR from "swr";

export const AnnotatedCanvas = ({
    imageSrc,
    imageData,
    onClick,
    categoryVisuParams,
    ...rest
}) => {
    const { data: imageBlob } = useSWR(() => imageSrc);

    const canvasRef = useRef(null);

    useEffect(() => {
        async function fetchAndDraw() {
            const imageBitmap = await createImageBitmap(imageBlob);

            const canvas = canvasRef.current;
            const context = canvas.getContext("2d");

            const canvasWidth = canvas.parentElement.offsetWidth;
            const canvasHeight = imageData.roi
                ? (imageData.roi[3] / imageData.roi[2]) * canvasWidth
                : (imageBitmap.height / imageBitmap.width) * canvasWidth;

            canvas.setAttribute("width", canvasWidth);
            canvas.setAttribute("height", canvasHeight);

            let naturalImageWidth;
            let naturalImageHeight;

            if (imageData.roi) {
                context.drawImage(
                    imageBitmap,
                    ...imageData.roi,
                    0,
                    0,
                    canvasWidth,
                    canvasHeight
                );

                naturalImageWidth = imageData.roi[2];
                naturalImageHeight = imageData.roi[3];
            } else {
                context.drawImage(imageBitmap, 0, 0, canvasWidth, canvasHeight);

                naturalImageWidth = imageBitmap.width;
                naturalImageHeight = imageBitmap.height;
            }

            if (imageData.annotations) {
                imageData.annotations.forEach((annotation) => {
                    const category = categoryVisuParams?.find(
                        ({ id }) => id === annotation.category_id
                    );

                    // If category is not found at all, we still draw the annotation.
                    if (category?.checked || !category) {
                        const color = category?.color ?? "red";
                        const scaledWidth = canvasWidth / naturalImageWidth;
                        const scaledHeight = canvasHeight / naturalImageHeight;

                        // Draw bounding box
                        const { segmentation, bbox } = annotation;

                        context.strokeStyle = color;
                        context.lineWidth = category?.lineWidth ?? 3;

                        if (bbox) {
                            context.strokeRect(
                                bbox[0] * scaledWidth,
                                bbox[1] * scaledHeight,
                                bbox[2] * scaledWidth,
                                bbox[3] * scaledHeight
                            );
                        } else if (segmentation) {
                            segmentation.forEach((polygon) => {
                                context.beginPath();
                                for (let i = 0; i < polygon.length; i += 2) {
                                    context.lineTo(
                                        polygon[i] * scaledWidth,
                                        polygon[i + 1] * scaledHeight
                                    );
                                }
                                context.fillStyle = "transparent";
                                context.closePath();
                                context.stroke();
                                context.fill();
                            });
                        }
                    }
                });
            }
        }
        if (imageBlob) fetchAndDraw();
    }, [imageData, imageBlob, categoryVisuParams]);

    return (
        <Box
            onClick={onClick}
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "middle",
                justifyContent: "center",
            }}
        >
            {/* Display only when image is ready, I avoid using onLoad canvas event because it is
                not triggered if image is preloaded */}
            <canvas ref={canvasRef} {...rest} />
        </Box>
    );
};

AnnotatedCanvas.propTypes = {
    imageSrc: PropTypes.string,
    imageData: PropTypes.object,
    onClick: PropTypes.func,
    categoryVisuParams: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            checked: PropTypes.bool.isRequired,
            lineWidth: PropTypes.number.isRequired,
            color: PropTypes.string.isRequired,
        })
    ),
};
