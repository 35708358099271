import {
    StyledEngineProvider,
    ThemeProvider,
    createTheme,
} from "@mui/material/styles";
import shadows, { customShadows } from "./shadows";

import { CssBaseline } from "@mui/material";
import PropTypes from "prop-types";
import { overrideComponentsStyle } from "./overrides";
import palette from "./palette";
import typography from "./typography";

const theme = {
    palette,
    shape: { borderRadius: 5 },
    typography,
    shadows,
    customShadows,
    components: overrideComponentsStyle,
};

export const ThemeConfig = ({ children }) => (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={createTheme(theme)}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    </StyledEngineProvider>
);

ThemeConfig.propTypes = {
    children: PropTypes.node,
};
