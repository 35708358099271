/**
 * A function that adds search parameters to a url.
 * https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/URLSearchParams
 * @param {URL} url
 * @param {Record<string, string>} params
 * @returns
 */
export const addSearchParams = (url, params = {}) =>
    new URL(
        `${url.origin}${url.pathname}?${new URLSearchParams([
            ...Array.from(url.searchParams.entries()),
            ...Object.entries(params),
        ]).toString()}`
    );
