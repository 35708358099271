import { useMemo, useState } from "react";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { ClassificationTemplate } from "./templates/ClassificationTemplate/ClassificationTemplate";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { Grid } from "@mui/material";
import { MODEL_TEMPLATES } from "../../constants/index";
import { ModelIdCard } from "./components/ModelIdCard/ModelIdCard";
import { SemanticSegmentationTemplate } from "./templates/SemanticSegmentationTemplate/SemanticSegmentationTemplate";
import { ValidationReportSkeleton } from "./ValidationReportSkeleton";
import { VersionTimeline } from "./components/VersionTimeline/VersionTimeline";
import { useParams } from "react-router-dom";
import useSWR from "swr";

const refetchOptions = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
};

export const ValidationReport = () => {
    const { modelUuid } = useParams();

    const {
        data: model,
        error: modelFetchError,
        isValidating: modelIsValidating,
    } = useSWR(
        `${BACKEND_ROUTES.DEEP_LEARNING_MODELS}/${modelUuid}`,
        refetchOptions
    );

    const {
        data: modelData,
        error: modelDataFetchError,
        isValidating: modelDataIsValidating,
    } = useSWR(model?.presignedUrl, refetchOptions);

    const mergedModelData = useMemo(
        () => ({ ...model, ...modelData }),
        [model, modelData]
    );

    const loading = modelDataIsValidating || modelIsValidating;

    const modelVersions = useMemo(
        () =>
            modelData?.versions.map(({ images, ...modelData }) => modelData) ??
            [],
        [modelData]
    );

    const [selectedModelVersion, setSelectedModelVersion] = useState(
        modelVersions[modelVersions.at(-1)] // Latest default selected
    );
    const [comparedModelVersion, setComparedModelVersion] = useState(null);

    const { data: versionData, isValidating: versionDataIsValidating } = useSWR(
        selectedModelVersion &&
            `${BACKEND_ROUTES.DEEP_LEARNING_MODELS}/${modelUuid}/versions/${selectedModelVersion?.name}`,
        refetchOptions
    );

    const {
        data: comparedVersionData,
        isValidating: comparedVersionDataIsValidating,
    } = useSWR(
        comparedModelVersion &&
            `${BACKEND_ROUTES.DEEP_LEARNING_MODELS}/${modelUuid}/versions/${comparedModelVersion?.name}`,
        refetchOptions
    );

    const images = useMemo(
        () =>
            modelData?.versions.find(
                ({ name }) => name === selectedModelVersion?.name
            )?.images ?? [],
        [modelData, selectedModelVersion]
    );

    const [filteredOutClasses, setFilteredOutClasses] = useState([]);

    const handleFilterClass = (classId) => {
        if (filteredOutClasses.includes(classId))
            setFilteredOutClasses(
                filteredOutClasses.filter((id) => classId !== id)
            );
        else setFilteredOutClasses([...filteredOutClasses, classId]);
    };

    const classes = useMemo(
        () =>
            modelData?.classes
                ?.sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                )
                .filter((c) => !filteredOutClasses.includes(c.classId)) ?? [],
        [modelData, filteredOutClasses]
    );

    const modelIdCard = (
        <ModelIdCard
            model={mergedModelData}
            selectedModelVersion={selectedModelVersion}
            handleFilterClass={handleFilterClass}
            filteredOutClasses={filteredOutClasses}
        />
    );
    const renderTemplate = () => {
        switch (modelData?.template) {
            case MODEL_TEMPLATES.SEMANTIC_SEGMENTATION:
                return (
                    <SemanticSegmentationTemplate
                        versionData={versionData}
                        dataset={mergedModelData?.dataset}
                        comparedVersionData={comparedVersionData}
                        classes={classes}
                        modelIdCard={modelIdCard}
                        selectedModelVersion={selectedModelVersion}
                        versionDataIsValidating={
                            versionDataIsValidating ||
                            comparedVersionDataIsValidating
                        }
                        versionTimeline={
                            <VersionTimeline
                                classes={classes}
                                versions={modelVersions}
                                selectedModelVersion={selectedModelVersion}
                                setSelectedModelVersion={
                                    setSelectedModelVersion
                                }
                                setComparedModelVersion={
                                    setComparedModelVersion
                                }
                                comparedModelVersion={comparedModelVersion}
                            />
                        }
                    />
                );
            case MODEL_TEMPLATES.CLASSIFICATION:
                return (
                    <ClassificationTemplate
                        versionData={versionData}
                        dataset={mergedModelData?.dataset}
                        classes={classes}
                        modelIdCard={modelIdCard}
                        versionTimeline={
                            <VersionTimeline
                                classes={classes}
                                versions={modelVersions}
                                selectedModelVersion={selectedModelVersion}
                                setSelectedModelVersion={
                                    setSelectedModelVersion
                                }
                                dataset={mergedModelData?.standardDataset}
                            />
                        }
                    />
                );
            default:
                return <>template not found</>;
        }
    };

    const mergedError = modelFetchError || modelDataFetchError;
    if (mergedError) return <FetchErrorAlert error={mergedError} />;

    return (
        <Grid container>
            {loading ? <ValidationReportSkeleton /> : renderTemplate()}
        </Grid>
    );
};
