import { ArrowDropDown, ArrowDropUp, Image, Remove } from "@mui/icons-material";
import {
    Box,
    Chip,
    LinearProgress,
    Paper,
    Popover,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { Fragment, useState } from "react";

import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";

export const VersionTimelineItem = ({
    previousVersion,
    classes,
    version,
    versionIsSelected,
    versionIsCompared,
    setSelectedModelVersion,
    setComparedModelVersion,
    isLatest,
}) => {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const accuracy =
        version.accuracyByClass && classes.length > 0
            ? (classes.reduce(
                  (a, b) => a + version.accuracyByClass[b.classId],
                  0
              ) /
                  classes.length) *
              100
            : null;
    return (
        <Fragment key={version.versionId}>
            {version.accuracyByClass && (
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: "none",
                        ml: 1,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Box sx={{ p: 1 }}>
                        <Stack direction="column" spacing={1}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Typography>Précision</Typography>
                                {accuracy != null && (
                                    <Typography color="primary">
                                        {accuracy.toFixed(1)}%
                                    </Typography>
                                )}
                            </Stack>
                            {classes.map(({ classId, name }) => {
                                const value = version.accuracyByClass[classId];
                                const diff = previousVersion
                                    ? value -
                                      previousVersion.accuracyByClass[classId]
                                    : 0;
                                return (
                                    value != null && (
                                        <Stack key={classId}>
                                            <LinearProgress
                                                variant="determinate"
                                                value={parseInt(value * 100)}
                                                sx={{
                                                    background: "lightgray",
                                                    "& .MuiLinearProgress-bar":
                                                        {
                                                            backgroundColor:
                                                                classes.find(
                                                                    ({
                                                                        classId:
                                                                            id,
                                                                    }) =>
                                                                        id ===
                                                                        classId
                                                                )?.color,
                                                        },
                                                }}
                                            />
                                            <Stack
                                                justifyContent="space-between"
                                                direction="row"
                                                alignItems="center"
                                                spacing={3}
                                            >
                                                <Typography fontSize="small">
                                                    {name}
                                                </Typography>
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center"
                                                >
                                                    <Typography fontSize="small">
                                                        {(value * 100).toFixed(
                                                            1
                                                        )}
                                                        %
                                                    </Typography>
                                                    {/* Comparator */}
                                                    <Stack
                                                        direction="row"
                                                        fontSize="small"
                                                        alignItems="center"
                                                        color={
                                                            diff === 0
                                                                ? "lightgray"
                                                                : diff > 0
                                                                ? "green"
                                                                : "red"
                                                        }
                                                    >
                                                        {diff === 0 ? (
                                                            <Remove />
                                                        ) : (
                                                            <>
                                                                {diff < 0 ? (
                                                                    <ArrowDropDown />
                                                                ) : (
                                                                    <ArrowDropUp />
                                                                )}
                                                                {(
                                                                    diff * 100
                                                                ).toFixed(1)}
                                                            </>
                                                        )}
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    )
                                );
                            })}
                        </Stack>
                    </Box>
                </Popover>
            )}
            <Paper
                key={version.name}
                sx={{
                    "&:hover": { background: "white" },
                    cursor: "pointer",
                    background: versionIsSelected ? "white" : "#00000005",
                    borderRadius: "5px",
                    p: 1,
                    m: 0.3,
                    outline: versionIsCompared
                        ? `2px solid ${theme.palette.primary.main}`
                        : "none",
                }}
                elevation={versionIsSelected ? 10 : 0}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                onClick={(event) => {
                    if (event.ctrlKey && setComparedModelVersion) {
                        if (versionIsCompared) {
                            setComparedModelVersion(null);
                        } else if (!versionIsSelected) {
                            setComparedModelVersion(version);
                        }
                    } else {
                        setSelectedModelVersion(version);
                        if (versionIsCompared) setComparedModelVersion(null);
                    }
                }}
            >
                <Stack direction="column">
                    <Stack
                        direction="row"
                        gap={0.5}
                        justifyContent={"space-between"}
                    >
                        <Typography fontSize="small">{version.date}</Typography>
                        <Tooltip title="Images">
                            <Chip
                                size="small"
                                color="default"
                                icon={<Image fontSize="small" />}
                                label={version.currentImgNumber}
                            />
                        </Tooltip>
                    </Stack>

                    <Stack direction="row" alignItems="center" gap={0.5}>
                        <Typography
                            variant="filled"
                            sx={{ fontWeight: "bold" }}
                        >
                            {version.name}
                        </Typography>
                        {accuracy !== null && (
                            <Typography fontSize="small" color="primary">
                                {accuracy.toFixed(1)}%
                            </Typography>
                        )}
                        {versionIsCompared && (
                            <Typography
                                variant="filled"
                                sx={{
                                    fontWeight: "bold",
                                    color: "primary.main",
                                }}
                            >
                                compared
                            </Typography>
                        )}
                    </Stack>
                </Stack>
                <Stack direction="row" gap={0.7}>
                    {isLatest && (
                        <Chip
                            size="small"
                            sx={{
                                borderRadius: 1,
                                color: "secondary.main",
                                background: theme.palette.secondary.lighter,
                            }}
                            label="Latest"
                        />
                    )}
                    {version.tags?.map((tag) => (
                        <Chip
                            key={tag}
                            sx={{ borderRadius: 1 }}
                            size="small"
                            label={tag}
                        />
                    ))}
                </Stack>
                <Typography variant="body2" color="gray">
                    {version.message}
                </Typography>
            </Paper>
        </Fragment>
    );
};
VersionTimelineItem.propTypes = {
    version: PropTypes.object.isRequired,
    versionIsSelected: PropTypes.bool.isRequired,
    setSelectedModelVersion: PropTypes.func.isRequired,
    isLatest: PropTypes.bool.isRequired,
};
